<template lang="pug">
.atendimento-prontuario
    .showing-image(v-if='anexoShowing' @click="anexoShowing = null")
        .ta-center(v-if='waitingAnexoShow')
            ProgressSpinner(strokeWidt='4')
        div(v-else)
            img.img-anexo(:src='anexoShowing.aq_anexo')

        .ta-center(v-if='waiting')
            ProgressSpinner(strokeWidt='4')

    Dialog.dialogApagarHistorico(header='Apagar registro' :visible.sync='dialogApagarHistorico' :modal='true')
        p Deseja apagar esse registro?
        .ta-center.my-2
            ProgressSpinner(v-if='waitingApagar' strokeWidth='6')
            div(v-else)
                label Motivo:
                textarea.d-block.mb-2(type="text" v-model='dialogApagarHistorico_ds_motivo')
                Button.p-button-danger(label='Sim' :disabled='dialogApagarHistorico_ds_motivo == ""' @click='apagarHistorico()')

    Dialog.dialogHistoricoProcedimentos(header='Detalhes do atendimento' :visible.sync='dialogHistorico' :modal='true')
        .no-results(v-if='waitingHistoricoOutros')
            ProgressSpinner(strokeWidth='5')
        .container(v-else-if='historicoSelecionado != null')
            Panel.panel-list(header='Informações do atendimento' :toggleable='false')
                p <b>Data agendada:</b> {{ historicoSelecionado.dt_agenda_f }}&nbsp;&nbsp;&nbsp;<b>{{ historicoSelecionado.ie_retorno?'RETORNO':'' }}</b>
                p <b>Especialista:</b> {{ historicoSelecionado.nm_especialista }}
                p <b>Especialidade:</b> {{ historicoSelecionado.nm_especialidade }}

            Panel.my-2(header='Evolução do paciente' :toggleable='true' :collapsed='true')
                div(v-if='! historicoSelecionado.anexos.evolucao.length')
                    p.ta-center(style='font-size: 13px;') <b>Não existe evolução registrada.</b>
                Card.my-2(v-else v-for='evolucao,idx in historicoSelecionado.anexos.evolucao' :key='idx' :class='evolucao.ie_inativo?"a-inactive":""')
                    template(slot='content')
                        .p-grid
                            .p-col-6
                                p(style='margin: 0; font-size: 14px') <b>{{ evolucao.dt_criado_f }}</b> <b>{{ evolucao.ie_inativo?"- INATIVO":"" }}</b>
                            .p-col-6.ta-right(v-if='historicoSelecionado.permite_inativar && !evolucao.ie_inativo')
                                Button.p-button-danger.p-button-raised.p-button-rounded.button-apagarHistorico(icon='jam jam-minus-circle'
                                    @click='dialogApagarHistorico_id = evolucao.id; dialogApagarHistorico = true')
                        div(v-html='evolucao.ds_anexo')
                        div(v-if='evolucao.ie_inativo') <b>Motivo: <i>{{ evolucao.ds_motivo_inativo }}</i></b>

            Panel.my-2(header='Documentos anexados' :toggleable='true' :collapsed='true')
                div(v-if='! historicoSelecionado.anexos.documentos.length')
                    p.ta-center(style='font-size: 13px;') <b>Não existem Documentos anexados.</b>
                .file-container(v-else)
                    .file-item(v-for='documento,idx in historicoSelecionado.anexos.documentos' :key='idx' :class="documento.ie_inativo?'a-inactive':''"
                        v-tooltip.top="documento.ie_inativo?'anexo inativo':''")
                        Button.p-button-raised.button-file.p-button-text(icon="jam jam-attachment" type="button" :label="`${options.document[documento.ie_tipo_anexo]} ${documento.dt_criado_f}`" @click='openDocument({ id: documento.id, text: documento.ds_anexo, fileSrc: documento.aq_anexo })')

            Panel.my-2(header='Fotos anexadas' :toggleable='true' :collapsed='true')
                div(v-if='! historicoSelecionado.anexos.fotos.length')
                    p.ta-center(style='font-size: 13px;') <b>Não existem Fotos anexadas.</b>
                .img-container(v-else)
                    .img-box(v-for='foto,idx in historicoSelecionado.anexos.fotos' :key='idx')
                        .img-item(v-if="foto.tipo_arquivo === 'pdf'")
                            a(target='_blank' :href='foto.aq_anexo')
                                img(style='width:100%;border-radius:.25em;cursor:pointer' src="./../../assets/img/pdf-logo.png")
                        .img-item(v-else)
                            img(style='width:100%;border-radius:.25em;cursor:pointer' :src='foto.aq_anexo' @click='openImage(foto)')
                
    ProgressBar(v-if='waiting' mode="indeterminate")
    div(v-else)
        .p-grid
            .p-col-12.p-md-6(v-if='anotacao != null')
                Panel.mt-4(header='Evolução' :toggleable='windowInnerWidth < 576' :collapsed='windowInnerWidth < 576')
                    .notepad-aux.p-grid.p-fluid.p-align-center
                        .p-col-2.ta-center
                            span.jam.jam-info
                        .p-col-10
                            p As informações salvas poderão ser consultadas em próximos atendimentos ao paciente.
                    ckeditor(:editor="editor" v-model="anotacao" :config="editorConfig" :disabled='$parent.model.ie_status == "EX"')
                    .actions-wrapper.ta-right.mt-1(v-if='anotacao.length')
                        ProgressSpinner(v-if='waitingSave' strokeWidth='6')
                        Button.p-button-success.aux1(v-else icon='jam jam-save' label='Salvar' @click='saveNote()')
                //p.text-browser.ta-right(v-if='browser') browser v.: {{ browser }}

                Panel.mt-4(header='CID' :toggleable='windowInnerWidth < 576' :collapsed='windowInnerWidth < 576')
                    .p-grid.p-fluid
                        .p-col-12
                            ProgressBar(v-if='waitingCIDS' mode="indeterminate")
                            AutoComplete(v-model="selectedCID" @dropdown-click='onDropdownClick' @item-select='onDropdownClick' :filter='true' 
                                :suggestions="filteredCID" @onclick="addCID()" @complete="searchCID($event)" field="ds_cid_alt"
                                :disabled='$parent.model.ie_status == "EX"')
                            div(v-if='waitingCIDS')
                            Card.my-1(v-else v-for='(cid, index) in selectedCIDS' :key='cid.id')
                                template(slot='content')
                                    .p-grid
                                        .p-col-11
                                            p(style='margin: 0; font-size: 14px') <b>{{ cid.ds_cid_alt }}</b>
                                        .p-col-1.ta-center
                                            Button.p-button-danger.p-button-raised.p-button-rounded.button-apagarHistorico(icon='jam jam-minus-circle'
                                                @click='removeCID(cid, index)')

            .p-col-12.p-md-6(v-if='historico != null')

                .ta-center.waitingHistorico(v-if='waitingHistorico')
                    ProgressSpinner(strokeWidth='2')

                Panel.mt-4(v-else header='Histórico do paciente' :toggleable='windowInnerWidth < 576' :collapsed='windowInnerWidth < 576')
                    Comorbidades

                    Panel.historicoProcedimentos.mt-4(:header="`Minhas evoluções do paciente`" :toggleable='true' :collapsed='true')
                        .historico-container
                            div(v-if='!historico.length')
                                p.ta-center(style='font-size: 13px;') <b>Sem histórico de consultas.</b>
                            Card.my-1(v-else v-for='registro, idx in historico' :key='idx')
                                template(slot='content')
                                    .p-grid
                                        .p-col-6
                                            p(style='margin: 0; font-size: 12px') <b>{{ registro.dt_agenda_f }}</b>&nbsp;&nbsp;&nbsp;<b>{{ registro.ie_retorno?'RETORNO':'' }}</b>
                                        .p-col-6.ta-right
                                            Button.p-button-info.p-button-raised.p-button-rounded.button-modalHistorico(icon='jam jam-info' 
                                            @click='getAnexosHistorico({idx_historico: idx, ie_historico_outros: false}); dialogHistorico = true')
                                    p <b>{{ registro.nm_especialidade }}</b> - {{ registro.nm_especialista }}
                    
                    Panel.historicoProcedimentos.mt-4(:header="`Outras evoluções do paciente`" :toggleable='true' :collapsed='true')
                        .historico-container
                            div(v-if='!historicoOutros.length')
                                p.ta-center(style='font-size: 13px;') <b>Sem histórico de consultas.</b>
                            Card.my-1(v-else v-for='historico, idx in historicoOutros' :key='idx')
                                template(slot='content')
                                    .p-grid
                                        .p-col-6
                                            p(style='margin: 0; font-size: 12px') <b>{{ historico.dt_agenda_f }}</b>&nbsp;&nbsp;&nbsp;<b>{{ historico.ie_retorno?'RETORNO':'' }}</b>
                                        .p-col-6.ta-right
                                            Button.p-button-info.p-button-raised.p-button-rounded.button-modalHistorico(icon='jam jam-info' 
                                            @click='getAnexosHistorico({idx_historico: idx, ie_historico_outros: true}); dialogHistorico = true')
                                    p <b>{{ historico.nm_especialidade }}</b> - {{ historico.nm_especialista }}
                    Fotos
                Exames
</template>

<style lang="scss">
    .atendimento-prontuario {
        .ck-content {
            min-height: 280px;
        }
        .notepad-aux {
            font-size: 12px;
            background-color: #ddf0f5;
            border: 1px solid #c6e7f4;
            margin-top: 0;
            margin-bottom: 8px;
            .jam {
                font-size: 24px;
            }
            p {
                margin: 0;
            }
        }
        .text-browser {
            margin: 0;
            font-size: 12px;
            font-weight: 700;
            color: #888;
            text-transform: lowercase;
        }
        .actions-wrapper, .dialogApagarHistorico, .dialogHistoricoProcedimentos {
            .p-progress-spinner {
                width: 29px;
                height: auto;
            }
        }
        .showing-image {
            cursor: pointer;
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 10000;
            background: rgba(0, 0, 0, .75);
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2em;
            .img-anexo {
                max-width: 100%;
                max-height: 92vh;
            }
        }
        .dialogHistoricoProcedimentos {
            background-color: white;
            width: 47.5vw;
            height: 50vh;

            .p-dialog-content {
                min-width: 100%;
                min-height: 100%;

                .a-inactive {
                    background-color: #9b9a9a;
                }
                
                .no-results{
                    min-width: 100%;
                    min-height: 100%;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                
                .img-container {
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                    gap: 10px;

                    .img-box {
                        height: 200px;

                        .img-item {
                            width: 100%;
                            height: 100%;

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }

                .file-container {
                    display: flex;
                    flex-wrap: wrap;

                    .file-item {
                        border:1px solid #c8c8c8; 
                        border-radius: .25rem;
                        margin: 7.5px 5px;
                        padding: 0px;
                        overflow: hidden;

                        &.a-inactive {
                            .button-file{
                                background-color: #9b9a9a;
                                border: 1px solid #9b9a9a;

                                &:hover{
                                    background-color: #747373;
                                    border: 1px solid #747373;
                                }
                            }
                        }

                        button {
                            border-radius: 0px;
                        }

                        .button-file{
                            background-color: #e6e4e4;
                            color: #333333;
                            border: 1px solid #e6e4e4;
                        }

                        .button-file:hover {
                            background-color: #c9c8c8;
                            border: 1px solid #c9c8c8;
                        }
                    }
                }
            }
        }
        .waitingHistorico {
            .p-progress-spinner {
                margin-top: 20%;
                width: 60px;
                height: auto;
            }
        }
        .p-card-title {
            font-size: 14px;
        }
        .historico-anexos, .historicoProcedimentos {
            .p-card-body {
                padding: 10px;
                p {
                    margin: 0;
                }
            }
        }
        .historicoProcedimentos {
            .historico-container{
                padding-inline: 10px;
                max-height: calc(82.5px * 4);
                overflow-y: scroll;   
            }
        }
        .button-apagarHistorico, .button-modalHistorico {
            width: 24px !important;
            height: 24px;
            .p-button-icon-left {
                font-size: 16px !important;
            }
        }
    }
</style>

<script>
    import Panel from 'primevue/panel'
    import ProgressBar from 'primevue/progressbar'
    import Editor from 'primevue/editor'
    import Button from 'primevue/button'
    import ProgressSpinner from 'primevue/progressspinner'
    //import MultiSelect from 'primevue/multiselect';
    import Card from 'primevue/card'
    import Dropdown from 'primevue/dropdown'
    import Dialog from 'primevue/dialog'
    import ClassicEditor from './../../assets/ckeditor5/build/ckeditor' // https://ckeditor.com/ckeditor-5/online-builder/
    import AutoComplete from 'primevue/autocomplete';
    import Tooltip from 'primevue/tooltip'

    import { AtendimentoMedico, CIDS } from './../../middleware'
	import Fotos from './Atendimento.fotos'
    import Exames from './Atendimento.exames'
    import Comorbidades from './Atendimento.comorbidades'
    import moment from 'moment'

    export default {
        components: { Panel, AutoComplete, Dropdown, ProgressBar, Editor, Button, ProgressSpinner, Card, Dialog, Fotos, Exames, Comorbidades, Tooltip },
        directives: {tooltip: Tooltip},
        watch: {
            '$parent.model': function (model) {
                if (model) {
                    if (model.guiaeletronica_set[0]) {
                        this.idGuiaEletronica = model.guiaeletronica_set[0].id
                        this.getHistorico()
                    } else {
                        this.$router.go(-1)
						this.$toast.error('Agendamento sem guia', { duration: 3000 })
                    }
                }
            }
        },
        created () {
            // CIDS.findAll().then(response => {
            //     if(response.status == 200) {
            //         //this.options.cids = response.data;
            //         response.data.forEach(g => this.options.cids.push({ value: g.nr_cid, text: g.ds_cid }))
            //     }
            // })
            this.waitingCIDS = true;
            CIDS.find(parseInt(this.$route.params.id))
            .then(response => {
                this.waitingCIDS = false;
                if(response.status == 200) {
                    response.data.forEach(cids => {
                           cids.ds_cid_alt = `${cids.nr_cid} - ${ cids.ds_cid }`
                           this.selectedCIDS = response.data;
                    })
                }
            })
            navigator.sayswho= (function(){
                var ua= navigator.userAgent, tem,
                M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
                if(/trident/i.test(M[1])){
                    tem=  /\brv[ :]+(\d+)/g.exec(ua) || []
                    return 'IE '+(tem[1] || '')
                }
                if(M[1]=== 'Chrome'){
                    tem= ua.match(/\b(OPR|Edge)\/(\d+)/)
                    if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera')
                }
                M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?']
                if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1])
                return M.join(' ')
            })()
            this.browser = navigator.sayswho
        },
        data () {
            return {
                idGuiaEletronica: 0,
                historico: null,
                historicoSelecionado: null,
                historicoOutros: [],
                anexoShowing: null,
                anotacao: null,
                dialogApagarHistorico: false,
                dialogHistorico: false,
                waitingCIDS: false,
                dialogApagarHistorico_id: 0,
                dialogApagarHistorico_ds_motivo: '',
                waiting: false,
                selectedKey1: null,
                filteredCID: [],
                selectedCID: null,
                selectedCIDS: [],
                options: {
                    cids: [],
                    document: {
                    PE: "Pedido de exame",
                    RE: "Receita",
                    AM: "Atestado médico",
                    PM: "Encaminhamento/Parecer médico"
                }
                },
                model: {},
                waitingSave: false,
                waitingApagar: false,
                waitingHistorico: false,
                waitingAnexoShow: false,
                waitingHistoricoOutros: false,
                browser: null,
                windowInnerWidth: window.innerWidth,
                editor: ClassicEditor,
                editorConfig: {
                    toolbar: [ 'bold', 'italic', 'bulletedList', 'numberedList' ]
                }
            }
        },
        methods: {
            saveNote () {
                this.waitingSave = true
                AtendimentoMedico.saveAnexo(this.idGuiaEletronica, 'PR', this.anotacao).then(response => {
                    this.waitingSave = false
                    if (([200, 201]).includes(response.status)) {
                        this.$toast.success('Anotações salvas', { duration: 3000 })
                        this.getHistorico()
                    } else this.$toast.error('Erro ao salvar anotação', { duration: 3000 })
                })
            },
            onDropdownClick (val) {
                this.selectedCID = '';
                //console.log(this.$parent.model);
                //console.log(val.value);

                let dataSend = Object.assign({}, this.model)
                dataSend.cd_horario_agendamento = this.$parent.model.id;
                dataSend.cd_cid = [val.value.id];

                CIDS.save(dataSend).then(response => {
                    if(response.status === 200 || response.status === 201 || response.status === 204) {
                        //console.log(response);
                        this.selectedCIDS.push(val.value);
                    } else {
                        if(response.status == 400) {
                            if(response.data.cd_cid !== undefined) {
                                for(var i in response.data.cd_cid) {
                                    this.$toast.error(response.data.cd_cid[i], { duration: 3000 })
                                }
                            }
                        }
                    }
                })
                //console.log(this.selectedCIDS);
            },
            removeCID(cid, index) {
                //console.log(cid);
                //console.log('index', index);
                CIDS.delet(this.$parent.model.id, cid.id)
                .then(response => {
                    if(response.status == 200 || response.status == 201 || response.status == 204) {
                        this.$toast.info('CID removido.', { duration: 3000 })
                        this.selectedCIDS.splice(index, 1)
                        this.$parent.$refs.anexosComponent.refresh()
                    }else {
                        if(response.status == 400) {
                            if(response.data.detail !== undefined) {
                                this.$toast.error(response.data.detail, { duration: 3000 })
                            }
                        }
                    }
                })
            },
            searchCID(event) {
                //console.log(event.query);
                var params = { cid: event.query };
                CIDS.findAll(params).then(response => {
                    //console.log(response.data);
                   if(response.status === 200) {
                       response.data.forEach(cids => {
                           cids.ds_cid_alt = `${cids.nr_cid} - ${ cids.ds_cid }`
                           this.filteredCID = response.data;
                       })
                   }
                })
            },
            getHistorico () {
                this.waitingHistorico = true
                AtendimentoMedico.getHistorico(this.$parent.model.cd_paciente).then(response => {
                    this.anotacao = ''
                    this.historico = []
                    if (response.status == 200) {
                        response.data.historico.forEach(data => {
                            data.dt_agenda_f = moment(data.dt_agenda).format('DD/MM/YYYY')
                            this.historico.push(data)

                            // AtendimentoMedico.getAnexos(data.id, { ie_tipo_anexo: 'PR' }).then(response => {
                            //     if (response.status == 200) {
                            //         let anexos = response.data

                            //         data.anexos = anexos.filter(anexo => anexo.cd_guia_eletronica == data.id)
                            //         data.anexos.forEach(anexo => {
                            //             anexo.dt_criado_f = moment(anexo.dt_criado).format('DD/MM/YYYY - HH:mm:ss')
                            //         })

                            //         this.$forceUpdate()
                            //         this.waitingHistorico = false
                            //     } else this.waitingHistorico = false
                            // })
   
                        })
                        response.data.historico_outros.forEach(data =>{
                            data.dt_agenda_f = moment(data.dt_agenda).format('DD/MM/YYYY')
                            this.historicoOutros.push(data)
                        })
                        // AtendimentoMedico.getAnexos(this.idGuiaEletronica, { ie_tipo_anexo: 'PR' }).then(response => {
                        //     console.log('procurando anexo' + this.idGuiaEletronica)
                        //     if (response.status == 200) {
                        //         let anexos = response.data
                        //         this.historico.forEach(registro => {
                        //             registro.anexos = anexos.filter(anexo => anexo.cd_guia_eletronica == registro.id)
                        //             registro.anexos.forEach(anexo => {
                        //                 anexo.dt_criado_f = moment(anexo.dt_criado).format('DD/MM/YYYY - HH:mm:ss')
                        //             })
                        //         })
                        //         this.$forceUpdate()
                        //         this.waitingHistorico = false
                        //     } else this.waitingHistorico = false
                        // })
                        this.waitingHistorico = false
                    } else this.waitingHistorico = false
                })
            },
            getAnexosHistorico({idx_historico, ie_historico_outros}) {
                this.waitingHistoricoOutros = true
                let historico = {}
                
                if (ie_historico_outros){
                    historico = this.historicoOutros[idx_historico]
                }else{
                    historico = this.historico[idx_historico]
                }

                if ('anexos' in historico) {
                    this.historicoSelecionado = historico
                    this.waitingHistoricoOutros = false
                    return true
                }

                AtendimentoMedico.getAnexos(historico.id, { historico_paciente: true }).then(response => {
                    if (response.status == 200) {

                        historico.anexos = {
                            'fotos': [],
                            'evolucao': [],
                            'documentos': []
                        }
                        
                        let anexos = response.data.filter(anexo => anexo.cd_guia_eletronica == historico.id)

                        anexos.forEach(anexo => {
                            anexo.dt_criado_f = moment(anexo.dt_criado).format('DD/MM/YYYY - HH:mm:ss')
                            switch (anexo.ie_tipo_anexo) {
                                case 'PR':
                                    historico.anexos.evolucao.push(anexo)
                                    break;
                                case 'FT':
                                    historico.anexos.fotos.push(anexo)
                                    break;
                                default:
                                    historico.anexos.documentos.push(anexo)
                                    break;
                            }
                        })

                        Object.values(historico.anexos).forEach(anexo => anexo.sort((a,b)=> a.dt_criado > b.dt_criado ? 1 : -1 ))

                        historico.permite_inativar = ! ie_historico_outros && this.$parent.model.ie_status != 'EX'
                        
                        if (ie_historico_outros){
                            this.historicoOutros[idx_historico] = historico
                        }else{
                            this.historico[idx_historico] = historico
                        }

                        this.historicoSelecionado = historico

                        this.waitingHistoricoOutros = false
                    }else{
                        this.waitingHistoricoOutros = false
                    }
                })
            },
            openDocument(document) {
                let w = window.open(document.fileSrc, "_blank");
                w.focus();
            },
            openImage(anexo) {
                this.anexoShowing = anexo
                let img = new Image()
                img.src = anexo.aq_anexo
                this.waitingAnexoShow = true
                img.onload = () => this.waitingAnexoShow = false
            },
            apagarHistorico () {
                this.waitingApagar = true
                this.dialogHistorico = false
                AtendimentoMedico.removeAnexo(this.dialogApagarHistorico_id, this.dialogApagarHistorico_ds_motivo).then(response => {
                    this.waitingApagar = false
                    if (([200, 201]).includes(response.status)) {
                        this.$toast.info('Registro removido', { duration: 3000 })
                        this.getHistorico()
                    } else if(response.status != 500)
                        this.$toast.error('Erro ao remover documento', { duration: 3000 })
                    this.dialogApagarHistorico = false
                    this.dialogApagarHistorico_ds_motivo = ''
                })
            }
        }
    }
</script>
