<template lang="pug">
.atendimento-fotos

    div.modal-galeria(v-if='anexoShowing' @click="anexoShowing = null")
        .ta-center(v-if='waitingAnexoShow')
            ProgressSpinner(strokeWidt='4')
        div(v-else)
            img.img-anexo(:src='anexoShowing.aq_anexo')

    .ta-center(v-if='waiting')
        ProgressSpinner(strokeWidt='4')

    div(v-else)

        Panel.mt-4(header='Anexos enviados pelo paciente')
            .ta-center(v-show='waitingThumbs')
                    ProgressSpinner(strokeWidt='4')
            div(v-show='! waitingThumbs')
                div(v-if='anexos.length' style='display:flex;width:100%;flex-wrap:wrap;')
                    .thumb-container-anexo(style='padding:.75em' v-for='anexo, idx in anexos' :key='idx')
                        div(v-if="anexo.tipo_arquivo === 'pdf'")
                            a(target='_blank' :href='anexo.aq_anexo')
                                img(style='width:100%;border-radius:.25em;cursor:pointer' src="./../../assets/img/pdf-logo.png")
                        div(v-else)
                            div(style='width:100%;cursor:pointer' :id="`__imgThumb_${ anexo.id }`" @click='showAnexo(anexo)')
                                canvas(style='display:none')
                div(v-else)
                    p.ta-center(style='font-size:.92em') <b>Paciente ainda não enviou anexo.</b>
</template>

<style lang="scss">
    .atendimento-fotos {
        .modal-galeria {
            cursor: pointer;
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 9;
            background: rgba(0, 0, 0, .75);
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2em;
            .img-anexo {
                max-width: 100%;
                max-height: 92vh;
            }
        }
        .thumb-container-anexo {
            width: 25%;
            @media all and (max-width: 640px) { width: 33.3333%; }
        }
        .p-progress-spinner {
            width: 64px;
            height: auto;
        }
    }
</style>

<script>
    import Panel from 'primevue/panel'
    import ProgressSpinner from 'primevue/progressspinner'

    import { AtendimentoMedico } from '../../middleware'
    export default {
        components: { Panel, ProgressSpinner },
        created () {
            this.idGuiaEletronica = this.$parent.$parent.idGuiaEletronica
            this.loadGaleria()
            // window.setInterval(() => { this.refreshGaleria() }, 5000)
        },
        data () {
            return {
                anexos: [],
                waiting: true,
                waitingThumbs: true,
                waitingAnexoShow: false,
                anexoShowing: null
            }
        },
        methods: {
            loadGaleria () {
                this.waiting = true
                AtendimentoMedico.getAnexos(this.idGuiaEletronica, { ie_tipo_anexo: 'FT' }).then(response => {
                    if (response.status == 200) {
                        this.anexos = response.data
                        this.waiting = false
                        
                        setTimeout(() => {
                            this.waitingThumbs = true
                            const loadThumbnail = (idx) => {
                                if (idx === this.anexos.length) {
                                    this.waitingThumbs = false
                                    return 0
                                }

                                if (this.anexos[idx].tipo_arquivo === 'pdf') loadThumbnail(++idx)
                                
                                else AtendimentoMedico.getAnexoArquivo(this.anexos[idx].id, 200).then(response => {
                                    if (response.status === 200) {
                                        let container = document.getElementById(`__imgThumb_${ this.anexos[idx].id }`)
                                        let canvas = document.querySelector(`#__imgThumb_${ this.anexos[idx].id } > canvas`)
                                        let context = canvas.getContext('2d')                                        
                                        let image = new Image()
                                        image.src = URL.createObjectURL(response.data)
                                        image.onload = () => {

                                            let cropSize = 0
                                            let offset = { x: 0, y: 0 }
                                            canvas.width = 200
                                            canvas.height = 200

                                            if (image.width > image.height) {
                                                cropSize = image.height
                                                offset.x = (image.width - image.height) / 2
                                            } else {
                                                cropSize = image.width
                                                offset.y = (image.height - image.width) / 2
                                            }

                                            context.drawImage(image,
                                                offset.x, offset.y, cropSize, cropSize,
                                                0, 0, canvas.width, canvas.height)

                                            let imageThumb = document.createElement('img')
                                            imageThumb.setAttribute('src', canvas.toDataURL())
                                            imageThumb.style.width = '100%'
                                            imageThumb.style.borderRadius = '.25em'
                                            container.appendChild(imageThumb)
                                        }
                                    } loadThumbnail(++idx)
                                })

                            }
                            loadThumbnail(0)
                        })
                        
                    } else this.waiting = false
                })
            },
            showAnexo (anexo) {
                this.anexoShowing = anexo
                let img = new Image()
                img.src = anexo.aq_anexo
                this.waitingAnexoShow = true
                img.onload = () => this.waitingAnexoShow = false
            },
            refreshGaleria () {
                AtendimentoMedico.getAnexos(this.idGuiaEletronica, { ie_tipo_anexo: 'FT' }).then(response => {
                    if (response.status === 200) {
                        if (JSON.stringify(response.data.map(a => a.id)) !== JSON.stringify(this.anexos.map(a => a.id))) this.loadGaleria()
                    }
                })
            }
        }
    }
</script>