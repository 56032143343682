<template>
    <div id="app">
        <h1>Ficha de Avaliação Pré-Anestésica</h1>

        <form @submit.prevent="submitForm">
            <div class="form-section">
                <label for="nome">NOME:</label>
                <input type="text" id="nome" v-model="form.nome">

                <label for="prontuario">Prontuário:</label>
                <input type="text" id="prontuario" v-model="form.prontuario">

                <label for="idade">Idade:</label>
                <input type="number" id="idade" v-model="form.idade">

                <label>Gênero:</label>
                <label><input type="radio" v-model="form.genero" value="M"> M</label>
                <label><input type="radio" v-model="form.genero" value="F"> F</label>

                <label for="cirurgia">Cirurgia proposta:</label>
                <input type="text" id="cirurgia" v-model="form.cirurgia">

                <label for="dataAvaliacao">Data da Avaliação:</label>
                <input type="date" id="dataAvaliacao" v-model="form.dataAvaliacao">

                <label for="cirurgiao">Cirurgião:</label>
                <input type="text" id="cirurgiao" v-model="form.cirurgiao">

                <label for="dataCirurgia">Data da Cirurgia:</label>
                <input type="date" id="dataCirurgia" v-model="form.dataCirurgia">

                <label for="peso">Peso (kg):</label>
                <input type="number" id="peso" v-model="form.peso">

                <label for="altura">Altura (cm):</label>
                <input type="number" id="altura" v-model="form.altura">

                <label for="dum">DUM:</label>
                <input type="text" id="dum" v-model="form.dum">

                <label for="fc">FC (bpm):</label>
                <input type="number" id="fc" v-model="form.fc">

                <label for="pa">PA (mmHg):</label>
                <input type="text" id="pa" v-model="form.pa">

                <label for="tax">Tax (°C):</label>
                <input type="number" id="tax" v-model="form.tax">
            </div>

            <div class="form-section">
                <h2>Antecedentes Pessoais e Anestésicos</h2>


                <div>
                    <h4 class="checkbox-group-title">ANTECEDENTES FAMILIARES</h4>
                    <div class="checkbox-group">
                        <label><input type="checkbox" v-model="form.antecedentesFamiliares.hipertensao">
                            Hipertensão</label>
                        <label><input type="checkbox" v-model="form.antecedentesFamiliares.diabetes"> Diabetes</label>
                        <label><input type="checkbox" v-model="form.antecedentesFamiliares.coronariopatia">
                            Coronariopatia</label>
                        <label><input type="checkbox" v-model="form.antecedentesFamiliares.alergias"> Alergias</label>
                        <label><input type="checkbox" v-model="form.antecedentesFamiliares.hipertermiaMaligna">
                            Hipertermia
                            Maligna</label>
                    </div>
                </div>

                <div>
                    <h4 class="checkbox-group-title">CARDIOVASCULAR</h4>
                    <div class="checkbox-group">
                        <label><input type="checkbox" v-model="form.cardiovascular.nenhum"> Nenhum</label>
                        <label><input type="checkbox" v-model="form.cardiovascular.hipertensao"> Hipertensão</label>
                        <label><input type="checkbox" v-model="form.cardiovascular.arritmia"> Arritmia</label>
                        <label><input type="checkbox" v-model="form.cardiovascular.sopro"> Sopro</label>
                        <label><input type="checkbox" v-model="form.cardiovascular.valvopatia"> Valvopatia</label>
                        <label><input type="checkbox" v-model="form.cardiovascular.angina"> Angina</label>
                        <label><input type="checkbox" v-model="form.cardiovascular.infarto"> Infarto</label>
                        <label><input type="checkbox" v-model="form.cardiovascular.insufCardiaca"> Insuficiência
                            Cardíaca</label>
                    </div>

                </div>

                <div>
                    <h4 class="checkbox-group-title">RESPIRATÓRIO</h4>
                    <div class="checkbox-group">
                        <label><input type="checkbox" v-model="form.respiratorio.nenhum"> Nenhum</label>
                        <label><input type="checkbox" v-model="form.respiratorio.secrecao"> Secreção</label>
                        <label><input type="checkbox" v-model="form.respiratorio.tosse"> Tosse</label>
                        <label><input type="checkbox" v-model="form.respiratorio.febre"> Febre</label>
                        <label><input type="checkbox" v-model="form.respiratorio.dispneia"> Dispneia</label>
                        <label><input type="checkbox" v-model="form.respiratorio.asma"> Asma</label>
                        <label><input type="checkbox" v-model="form.respiratorio.pneumonia"> Pneumonia</label>
                        <label><input type="checkbox" v-model="form.respiratorio.dpoc"> DPOC</label>
                    </div>
                </div>

                <div>
                    <h4 class="checkbox-group-title">NEUROLÓGICO</h4>
                    <div class="checkbox-group">
                        <label><input type="checkbox" v-model="form.neurologico.nenhum"> Nenhum</label>
                        <label><input type="checkbox" v-model="form.neurologico.ansiedade"> Ansiedade</label>
                        <label><input type="checkbox" v-model="form.neurologico.depressao"> Depressão</label>
                        <label><input type="checkbox" v-model="form.neurologico.cefaleia"> Cefaleia</label>
                        <label><input type="checkbox" v-model="form.neurologico.epilepsia"> Epilepsia</label>
                        <label><input type="checkbox" v-model="form.neurologico.neuropatias"> Neuropatias</label>
                        <label><input type="checkbox" v-model="form.neurologico.deficit"> Déficit</label>
                        <label><input type="checkbox" v-model="form.neurologico.avc"> AVC</label>
                    </div>
                </div>

                <div>
                    <h4 class="checkbox-group-title">
                        METABÓLICO
                    </h4>
                    <div class="checkbox-group">
                        <label><input type="checkbox" v-model="form.metabolico.nenhum"> Nenhum</label>
                        <label><input type="checkbox" v-model="form.metabolico.diabetes"> Diabetes</label>
                        <label><input type="checkbox" v-model="form.metabolico.perdaPonderal"> Perda Ponderal</label>
                        <label><input type="checkbox" v-model="form.metabolico.obesidade"> Obesidade</label>
                        <label><input type="checkbox" v-model="form.metabolico.tireoidopatia"> Tireoidopatia</label>
                        <label><input type="checkbox" v-model="form.metabolico.gravidez"> Gravidez</label>
                    </div>
                </div>

                <div>
                    <h4 class="checkbox-group-title">DIGESTIVO</h4>
                    <div class="checkbox-group">
                        <label><input type="checkbox" v-model="form.digestivo.nenhum"> Nenhum</label>
                        <label><input type="checkbox" v-model="form.digestivo.nauseaVomito"> Náusea/Vômito</label>
                        <label><input type="checkbox" v-model="form.digestivo.gastriteUlcera">
                            Gastrite/Úlcera</label>
                        <label><input type="checkbox" v-model="form.digestivo.cirrose"> Cirrose</label>
                        <label><input type="checkbox" v-model="form.digestivo.sangramento"> Sangramento</label>
                        <label><input type="checkbox" v-model="form.digestivo.diarreia"> Diarreia</label>
                    </div>
                </div>

                <div>
                    <h4 class="checkbox-group-title">ORTOPÉDICO</h4>
                    <div class="checkbox-group">
                        <label><input type="checkbox" v-model="form.ortopedico.nenhum"> Nenhum</label>
                        <label><input type="checkbox" v-model="form.ortopedico.herniaDeDisco"> Hérnia de
                            Disco</label>
                        <label><input type="checkbox" v-model="form.ortopedico.escoliose"> Escoliose</label>
                        <label><input type="checkbox" v-model="form.ortopedico.fratura"> Fratura</label>
                        <label><input type="checkbox" v-model="form.ortopedico.fixacaoNaColuna"> Fixação na
                            Coluna</label>
                        <label><input type="checkbox" v-model="form.ortopedico.lombociatalgia">
                            Lombociatalgia</label>
                    </div>
                </div>

                <div>
                    <h4 class="checkbox-group-title">RENAL</h4>
                    <div class="checkbox-group">
                        <label><input type="checkbox" v-model="form.renal.nenhum"> Nenhum</label>
                        <label><input type="checkbox" v-model="form.renal.infeccao"> Infecção</label>
                        <label><input type="checkbox" v-model="form.renal.calculos"> Cálculos</label>
                        <label><input type="checkbox" v-model="form.renal.oliguria"> Oligúria</label>
                        <label><input type="checkbox" v-model="form.renal.poliuria"> Poliúria</label>
                        <label><input type="checkbox" v-model="form.renal.insufRenal"> Insuficiência renal</label>
                    </div>
                </div>

                <div>
                    <h4 class="checkbox-group-title">HEMATOLOGIA</h4>
                    <div class="checkbox-group">
                        <label><input type="checkbox" v-model="form.hematologia.nenhum"> Nenhum</label>
                        <label><input type="checkbox" v-model="form.hematologia.anemia"> Anemia</label>
                        <label><input type="checkbox" v-model="form.hematologia.sangramentos"> Sangramentos</label>
                        <label><input type="checkbox" v-model="form.hematologia.hemofilia"> Hemofilia</label>
                        <label><input type="checkbox" v-model="form.hematologia.transfusoes"> Transfusões
                            anteriores</label>
                        <label><input type="checkbox" v-model="form.hematologia.testemunhasDeJeova"> Test. de
                            Jeová</label>
                    </div>
                </div>

                <div>
                    <h4 class="checkbox-group-title">ALERGIAS</h4>
                    <div class="checkbox-group">
                        <label><input type="checkbox" v-model="form.alergias.nenhum"> Nenhum</label>
                        <label><input type="checkbox" v-model="form.alergias.medicacao"> Medicação</label>
                        <label><input type="checkbox" v-model="form.alergias.alimentos"> Alimentos</label>
                        <label><input type="checkbox" v-model="form.alergias.edemaGlote"> Edema Glote</label>
                        <label><input type="checkbox" v-model="form.alergias.broncoespasmo"> Broncoespasmo</label>
                        <label><input type="checkbox" v-model="form.alergias.latex"> Látex</label>
                        <label><input type="checkbox" v-model="form.alergias.pcr"> PCR</label>
                    </div>
                </div>

                <div class="form-section">
                    <label for="alergiasQuais">Quais:</label>
                    <input type="text" id="alergiasQuais" v-model="form.alergiasQuais">
                </div>

                <div>
                    <h4 class="checkbox-group-title">DOENÇAS</h4>
                    <div class="checkbox-group">
                        <label><input type="checkbox" v-model="form.doencas.hepatiteA"> Hepatite A</label>
                        <label><input type="checkbox" v-model="form.doencas.hepatiteB"> Hepatite B</label>
                        <label><input type="checkbox" v-model="form.doencas.hepatiteC"> Hepatite C</label>
                        <label><input type="checkbox" v-model="form.doencas.hepatiteD"> Hepatite D</label>
                        <label><input type="checkbox" v-model="form.doencas.hiv"> HIV</label>
                        <label><input type="checkbox" v-model="form.doencas.tuberculose"> Tuberculose</label>
                        <label><input type="checkbox" v-model="form.doencas.chagas"> Chagas</label>
                        <label><input type="checkbox" v-model="form.doencas.outras"> Outras</label>
                    </div>
                </div>

                <div class="form-section">
                    <label for="doencasQuais">Quais:</label>
                    <input type="text" id="doencasQuais" v-model="form.doencasQuais">
                </div>

                <div>
                    <h4 class="checkbox-group-title">VÍCIOS</h4>
                    <div class="checkbox-group">
                        <label><input type="checkbox" v-model="form.vicios.nenhum"> Nenhum</label>
                        <label><input type="checkbox" v-model="form.vicios.tabagismo"> Tabagismo</label>
                        <label><input type="checkbox" v-model="form.vicios.etilismo"> Etilismo</label>
                        <label><input type="checkbox" v-model="form.vicios.drogasIlicitas"> Drogas Ilícitas</label>
                    </div>
                </div>

                <div class="form-section">
                    <label for="viciosQuais">Quais:</label>
                    <input type="text" id="viciosQuais" v-model="form.viciosQuais">
                </div>

                <div class="form-section">
                    <h3>CIRURGIAS PRÉVIAS</h3>
                    <label><input type="checkbox" v-model="form.cirurgiasPrevias.nenhum"> Nenhum</label>
                    <label><input type="checkbox" v-model="form.cirurgiasPrevias.sim"> Sim</label>
                    <label for="cirurgiasQuais">Quais:</label>
                    <input type="text" id="cirurgiasQuais" v-model="form.cirurgiasQuais">
                </div>

                <div class="form-section">
                    <h3>ANESTESIAS ANTERIORES</h3>
                    <label><input type="checkbox" v-model="form.anestesiasAnteriores.nenhum"> Nenhum</label>
                    <label><input type="checkbox" v-model="form.anestesiasAnteriores.sim"> Sim</label>
                    <label><input type="checkbox" v-model="form.vicios.raquianestesia"> Raquianestesia</label>
                    <label><input type="checkbox" v-model="form.vicios.peridural"> Peridural</label>
                    <label><input type="checkbox" v-model="form.vicios.bloqueioPeriferico"> Bloqueio Periférico</label>
                    <label><input type="checkbox" v-model="form.vicios.geral"> Geral</label>
                    <label><input type="checkbox" v-model="form.vicios.local"> Local</label>
                    <label for="anestesiasQuais">Quais:</label>
                    <input type="text" id="anestesiasQuais" v-model="form.anestesiasQuais">
                </div>

                <div class="form-section">
                    <h3>MEDICAÇÕES EM USO</h3>
                    <label><input type="checkbox" v-model="form.medicacoesEmUso.nenhum"> Nenhum</label>
                    <label><input type="checkbox" v-model="form.medicacoesEmUso.sim"> Sim</label>
                    <label for="medicacoesQuais">Quais:</label>
                    <input type="text" id="medicacoesQuais" v-model="form.medicacoesQuais">
                </div>
            </div>


            <div class="form-section">
                <h2>Exame Fisíco</h2>
                <label for="estado_geral">Estado Geral:</label>
                <input type="text" id="estado_geral" v-model="form.estado_geral">

                <label for="ac">AC:</label>
                <input type="text" id="ac" v-model="form.ac">

                <label for="ap">AP:</label>
                <input type="text" id="ap" v-model="form.ap">

                <label>Prótese dentária móvel:</label>
                <label><input type="radio" v-model="form.protese_dentaria_movel" value="N"> Não</label>
                <label><input type="radio" v-model="form.protese_dentaria_movel" value="S"> Sim</label>


                <label><input type="checkbox" v-model="form.consentimentos_assinados"> Consentimentos assinados</label>

                <label for="mallampatti">Mallampatti:</label>
                <label><input type="radio" v-model="form.mallampatti" value="I"> I</label>
                <label><input type="radio" v-model="form.mallampatti" value="II"> II</label>
                <label><input type="radio" v-model="form.mallampatti" value="III"> III</label>
                <label><input type="radio" v-model="form.mallampatti" value="IV"> IV</label>

                <label for="distancia_tireo_mento">Distância tireo-mento:</label>
                <label><input type="radio" v-model="form.distancia_tireo_mento" value=">6,0cm"> >6,0cm</label>
                <label><input type="radio" v-model="form.distancia_tireo_mento" value="<6,0cm"> &lt;6,0cm </label>

                <label for="distancia_esterno_mento">Distância esterno-mento:</label>
                <label><input type="radio" v-model="form.distancia_esterno_mento" value=">12,5cm"> >12,5cm</label>
                <label><input type="radio" v-model="form.distancia_esterno_mento" value="<12,5cm"> &lt;12,5cm </label>

                <label for="abertura_oral">Abertura oral:</label>
                <label><input type="radio" v-model="form.abertura_oral" value=">3cm"> >3cm</label>
                <label><input type="radio" v-model="form.abertura_oral" value="<3cm"> &lt;3cm </label>
            </div>

            <div class="form-section">
                <h2>Exames laboratoriais</h2>
                <label for="datas_exames_laboratoriais">Data exames laboratoriais:</label>
                <input type="date" id="datas_exames_laboratoriais" v-model="form.datas_exames_laboratoriais">

                <label for="nenhum_exame_laboratorial">Nenhum</label>
                <label><input type="checkbox" v-model="form.nenhum_exame_laboratorial"> Nenhum</label>

                <label for="Hb">Hb:</label>
                <input type="text" id="Hb" v-model="form.Hb">

                <label for="Ht">Ht:</label>
                <input type="text" id="Ht" v-model="form.Ht">

                <label for="plaquetas">Plaquetas:</label>
                <input type="text" id="plaquetas" v-model="form.plaquetas">

                <label for="tap">TAP:</label>
                <input type="text" id="tap" v-model="form.tap">

                <label for="inr">INR:</label>
                <input type="text" id="inr" v-model="form.inr">

                <label for="ttpa">TTPA:</label>
                <input type="text" id="ttpa" v-model="form.ttpa">

                <label for="na">Na:</label>
                <input type="text" id="na" v-model="form.na">

                <label for="k">K:</label>
                <input type="text" id="k" v-model="form.k">


                <label for="ca">Ca:</label>
                <input type="text" id="ca" v-model="form.ca">

                <label for="ur">Ur:</label>
                <input type="text" id="ur" v-model="form.ur">

                <label for="cr">Cr:</label>
                <input type="text" id="cr" v-model="form.cr">

                <label for="glicemia">Glicemia:</label>
                <input type="text" id="glicemia" v-model="form.glicemia">

                <h4>ECG</h4>
                <label><input type="radio" v-model="form.ecg" value="nao_tem"> Não tem</label>
                <label><input type="radio" v-model="form.ecg" value="normal"> Normal</label>
                <label><input type="radio" v-model="form.ecg" value="alterado"> Alterado</label>

                <h4>Raio X de Toráx</h4>
                <label><input type="radio" v-model="form.raio_x_torax" value="nao_tem"> Não tem</label>
                <label><input type="radio" v-model="form.raio_x_torax" value="normal"> Normal</label>
                <label><input type="radio" v-model="form.raio_x_torax" value="alterado"> Alterado</label>

                <h3>Outros</h3>
                <label for="eco">Eco:</label>
                <input type="text" id="eco" v-model="form.eco">

                <label for="avaliacao_especialista">Avaliação de Especialista:</label>
                <input type="text" id="avaliacao_especialista" v-model="form.avaliacao_especialista">

                <h4>P</h4>
                <label><input type="radio" v-model="form.p" value="I"> I</label>
                <label><input type="radio" v-model="form.p" value="II"> II</label>
                <label><input type="radio" v-model="form.p" value="III"> III</label>
                <label><input type="radio" v-model="form.p" value="IV"> IV</label>
                <label><input type="radio" v-model="form.p" value="V"> V</label>
                <label><input type="radio" v-model="form.p" value="VI"> VI</label>
                <label><input type="radio" v-model="form.p" value="E"> E</label>

                <label for="jejum_apartir_de">Jejum a partir de(h):</label>
                <input type="number" id="jejum_apartir_de" v-model="form.jejum_apartir_de">

                <label for="conduta">Conduta:</label>
                <label><input type="radio" v-model="form.conduta" value="apto"> Apto no momento</label>
                <label><input type="radio" v-model="form.conduta" value="nao_apto"> Não apto</label>
                <label><input type="radio" v-model="form.conduta" value="aguardo_exames"> Aguardo exames</label>

                <label for="cnoduta_orientacao">Orientação:</label>
                <input type="text" id="cnoduta_orientacao" v-model="form.conduta_orientacao">
            </div>
        </form>
    </div>
</template>


<style>
body {
    font-family: Arial, sans-serif;
    margin: 20px;
}

.form-section {
    margin-bottom: 20px;
}

.form-section label {
    display: block;
    margin-top: 10px;
}

.form-section input[type="text"],
.form-section input[type="number"],
.form-section input[type="date"],
.form-section textarea {
    width: 100%;
    padding: 5px;
    margin-top: 5px;
}

.form-section .checkbox-group {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
}

.checkbox-group-title {
    margin: 0;
}

.form-section .checkbox-group label {
    width: 150px;
}

.form-section .checkbox-group input[type="checkbox"] {
    margin-right: 5px;
}
</style>

<script>
const d = new Date();
const today = `${d.getFullYear()}-${new String(d.getMonth() + 1).padStart(2,'0')}-${new String(d.getDate()).padStart(2,'0')}`;

export default {
    props: ['patientName', 'patientAge',  'cd_guia_eletronica'],
    data: function () {
        
        return {
            form: {
                nome: this.patientName,
                prontuario: this.cd_guia_eletronica,
                idade: this.patientAge,
                genero: '',
                cirurgia: '',
                dataAvaliacao: today, //current
                cirurgiao: '',
                dataCirurgia: '',
                peso: '',
                altura: '',
                dum: '',
                fc: '',
                pa: '',
                tax: '',
                antecedentesFamiliares: {
                    hipertensao: false,
                    diabetes: false,
                    coronariopatia: false,
                    alergias: false,
                    hipertermiaMaligna: false,
                },
                cardiovascular: {
                    nenhum: false,
                    hipertensao: false,
                    arritmia: false,
                    sopro: false,
                    valvopatia: false,
                    angina: false,
                    infarto: false,
                    insufCardiaca: false,
                },
                respiratorio: {
                    nenhum: false,
                    secrecao: false,
                    tosse: false,
                    febre: false,
                    dispneia: false,
                    asma: false,
                    pneumonia: false,
                    dpoc: false,
                },
                neurologico: {
                    nenhum: false,
                    ansiedade: false,
                    depressao: false,
                    cefaleia: false,
                    epilepsia: false,
                    neuropatias: false,
                    deficit: false,
                    avc: false,
                },
                metabolico: {
                    nenhum: false,
                    diabetes: false,
                    perdaPonderal: false,
                    obesidade: false,
                    tireoidopatia: false,
                    gravidez: false,
                },
                digestivo: {
                    nenhum: false,
                    nauseaVomito: false,
                    gastriteUlcera: false,
                    cirrose: false,
                    sangramento: false,
                    diarreia: false,
                },
                ortopedico: {
                    nenhum: false,
                    herniaDeDisco: false,
                    escoliose: false,
                    fratura: false,
                    fixacaoNaColuna: false,
                    lombociatalgia: false,
                },
                renal: {
                    nenhum: false,
                    infeccao: false,
                    calculos: false,
                    oliguria: false,
                    poliuria: false,
                    insufRenal: false,
                },
                hematologia: {
                    nenhum: false,
                    anemia: false,
                    sangramentos: false,
                    hemofilia: false,
                    transfusoes: false,
                    testemunhasDeJeova: false,
                },
                alergias: {
                    nenhum: false,
                    medicacao: false,
                    alimentos: false,
                    edemaGlote: false,
                    broncoespasmo: false,
                    latex: false,
                    pcr: false,
                },
                alergiasQuais: '',
                doencas: {
                    hepatiteA: false,
                    hepatiteB: false,
                    hepatiteC: false,
                    hepatiteD: false,
                    hiv: false,
                    tuberculose: false,
                    chagas: false,
                    outras: false,
                },
                doencasQuais: '',
                vicios: {
                    nenhum: false,
                    tabagismo: false,
                    etilismo: false,
                    drogasIlicitas: false,
                },
                viciosQuais: '',
                cirurgiasPrevias: {
                    nenhum: false,
                    sim: false,
                },
                cirurgiasQuais: '',
                anestesiasAnteriores: {
                    nenhum: false,
                    sim: false,
                },
                anestesiasQuais: '',
                medicacoesEmUso: {
                    nenhum: false,
                    sim: false,
                },
                medicacoesQuais: '',
                estado_geral: '',
                ac: '',
                ap: '',
                protese_dentaria_movel: '',
                consentimentos_assinados: false,
                mallampatti: '',
                distancia_tireo_mento: '',
                distancia_esterno_mento: '',
                abertura_oral: '',
                datas_exames_laboratoriais: '',
                nenhum_exame_laboratorial: false,
                Hb: '',
                Ht: '',
                plaquetas: '',
                tap: '',
                inr: '',
                ttpa: '',
                na: '',
                k: '',
                ca: '',
                ur: '',
                cr: '',
                glicemia: '',
                ecg: '',
                raio_x_torax: '',
                eco: '',
                avaliacao_especialista: '',
                p: '',
                jejum_apartir_de: '',
                conduta: '',
                conduta_orientacao: '',
            },
        }
    },
    watch: {
        form: {
            handler: function (newVal) {
                this.$emit('submit_ficha_avaliacao', newVal);
            },
            deep: true
        }
    },
}
</script>