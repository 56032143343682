<template lang="pug">
    <div>
        Dialog.dialogNexoData(
            :visible.sync="modalNexoData",
            :modal="true"
            header='Emitir Receita'
            :closable='false'
            :autoLayout='true'
            )
                iframe(:src='iframeUrl' class='iframeNexo' :autoLayout='true')

        Button(label="Receita Nexodata" id="botaoShowPrescricao" @click='sendNexodata' v-show='!waitingiFrame')
        ProgressBar(v-show='waitingiFrame' mode="indeterminate")
        //- Button(label="Receita" id="botaoShowPrescricao")
    </div>
</template>

<script>
import Button from 'primevue/button'
import Dialog from "primevue/dialog";
import ProgressBar from "primevue/progressbar";
import AtendimentoMedico from './../../middleware/controllers/AtendimentoMedico'
import NexoData from './../../middleware/controllers/NexoData'
import { store } from './../../main'

export default {
    name: "Integration",
    components: {Button, Dialog, ProgressBar},
    props: {
       infoNexodata: {
           type: Object,
           required: true,
       },
       idGuia: {
           type: Number,
           required: true,
       },
       referencia: {
           type: Object,
           required: false,
       }
    },
    created() {
        this.initNexoData();
    },
    updated() {
        this.initNexoData();
    },
    data: () => {
        return {
            body: document.querySelector("body"),
            idPrescricao: 0,
            modalNexoData: false,
            waitingiFrame: false,
            iframeUrl: '',
            tokenPrescricao: '',
        };
    },
    methods: {
        initNexoData: async function () {
            store.commit('setPlataformaMemedPronta', true)
        },
        async sendNexodata() {
            const self = this;

            let presId = null;

            self.waitingiFrame = true;
            self.infoNexodata['Estabelecimento']['Logo'] =  "https://medclub.com.br/img/logo-medclub.dc3c2698.png"

            if(this.infoNexodata.IdPrescricao) {
                const response = await NexoData.novaSessao(this.infoNexodata.IdPrescricao)
                if ([200, 201].includes(response.status) && response.data?.data?.ModalURL) {
                    self.iframeUrl = response.data.data.ModalURL;
                    self.tokenPrescricao = response.data.data.Token;
                }
            } else {
                let referencia =  await NexoData.iniciarPrescricao(self.infoNexodata)
                if ([200, 201].includes(referencia.status) && referencia.data?.ModalURL) {
                    self.iframeUrl = referencia.data.ModalURL;
                    self.tokenPrescricao = referencia.data.Token;

                    const params = {
                        id_nexodata: referencia.data.idPrescricao,
                        cd_guia_eletronica: self.idGuia,
                        token: self.tokenPrescricao
                    }
                    AtendimentoMedico.savePrescricaoNexoData(params).then(() => {});
                    presId = referencia.data.idPrescricao;
                }
            }

            if (!self.iframeUrl) {
                this.$toast.error("Não foi possível iniciar a receita Nexodata.", { duration: 3000 });
                self.waitingiFrame = false;
                return;
            }

            window.addEventListener("message", (event) => {
                const validEvents = ['cancel','excluded','prescricao'];
                if(validEvents.includes(event.data.type)) {
                    const params = {
                        id_nexodata: presId ? presId : self.referencia.IdPrescricao,
                        cd_guia_eletronica: self.idGuia,
                        token: self.tokenPrescricao
                    }

                    //Salva a receita na API
                    AtendimentoMedico.savePrescricaoNexoData(params).then(() => {
                        //Insere no Store de que a receita já está na API e o carregamento pode terminar
                        self.modalNexoData = false;
                        store.commit('setPrescricaoEmitida', true)
                    })
                }
            }, false);

            self.modalNexoData = true;
            self.waitingiFrame = false;
        }
    },
};
</script>

<style scoped>
.memed-container {
    margin: 0 auto;
    border: 2px solid black;
    /* Largura mínima 820px */
    width: 820px;
    height: 710px;
}
.dialogNexoData {
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: #fff;
}
.iframeNexo {
    width: 800px;
    height: 700px;
}
@media (max-width: 425px) {
   .iframeNexo {
        width: 320px;
        height: 600px;
    }
}
</style>
