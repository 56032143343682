<template lang="pug">
.atendimento-anexos(style='margin-top: 30px;')
    Dialog.dialogRemover(header='Remover Documento' :visible.sync='dialogRemover' :modal='true' @show='submitted = false')
        p.ta-center Deseja remover o Documento?
        .ta-center.my-4
            label Motivo:
            textarea.d-block.mb-2(type="text" v-model='documentoASerRemovidoMotivo')
            Button.p-button-danger(label='Remover' :disabled='documentoASerRemovidoMotivo == ""' @click='remove()')

    Dialog.dialogImprimir(header='Imprimir Documento' :visible.sync='dialogoImprimir' :modal='true' @show='submitted = false')
        p.ta-center Escolha um tipo de receita
        .ta-center.my-4
            //- ProgressSpinner(v-if='waitingFinalizar' strokeWidth='6')
            Button.p-button-info.mx-2(label='Comum' :loading="loadingDialogButton" @click='loadingDialogButton=true; printDocument(anexoASerImpresso)')
            Button.p-button-info.mx-2(label='Duas vias' :loading="loadingDialogButton" @click='loadingDialogButton=true; print2Documents(anexoASerImpresso)')

    Dialog.dialogAutenticar(header='Assinar Documentos' :visible.sync='dialogAutenticar' :modal='true' @show='submitted = false')

        .ta-center(v-if='waiting === true' style='padding: 60px 0;')
            ProgressSpinner.spinnerAutenticar(strokeWidth='2')

        .p-grid.p-fluid.p-align-end(v-else)

            .p-col-12.logoSoluti-wrapper
                img(src='./../../assets/img/logo-soluti.jpg')

            .p-col-12(v-if='ie_assinar == 1' :class="{ 'form-group--error': submitted && $v.nr_cpf.$error }")
                label.form-label CPF:
                InputMask(v-model='$v.nr_cpf.$model' type='tel' mask='999.999.999-99')
                .feedback--errors(v-if='submitted && $v.nr_cpf.$error')
                    .form-message--error(v-if="!$v.nr_cpf.validateCpf") <b>CPF</b> inválido.

            .p-col-12(v-if='ie_assinar == 1' :class="{ 'form-group--error': submitted && $v.otp_token.$error }")
                label.form-label Token (OTP):
                InputText(v-model='$v.otp_token.$model')
                .feedback--errors(v-if='submitted && $v.otp_token.$error')
                    .form-message--error(v-if="!$v.otp_token.minLength") <b>Token</b> deve ter pelo menos 2 caracteres.
                    .form-message--error(v-if="!$v.otp_token.required") <b>Token</b> é obrigatório.

            .p-col-12(v-if='ie_assinar == 1' :class="{ 'form-group--error': submitted && $v.ds_destinatario.$error }")
                label.form-label Destinatário (E-mail):
                InputText(v-model='$v.ds_destinatario.$model')
                .feedback--errors(v-if='submitted && $v.ds_destinatario.$error')
                    .form-message--error(v-if="!$v.ds_destinatario.email") <b>E-mail</b> é inválido.
                    .form-message--error(v-if="!$v.ds_destinatario.required") <b>E-mail</b> é obrigatório.

            .p-col-6.ta-center
                label.form-label.mb-1 Assinar Digitalmente:
                InputSwitch(v-model='ie_assinar')

            .p-col-6
                Button.btn-executar.p-button-success(icon='jam jam-key' :label="`${ ie_assinar==true ? 'Enviar e Assinar' : 'Confirmar Envio' }`" @click='assinarDocumentos()')

    ProgressBar(v-if='waiting || waitingToken || waitingGuia' mode="indeterminate")
    div(v-else)
        .p-grid
            .p-col-12(:class="{ 'p-md-8': item_clicado && help_doctor_ativado }")
                Panel.my-4(:header="document.id && areaSelected == 2 ? 'Visualização' : 'Emitir Documentos'" :toggleable='false' :collapsed='false')
                    .area1(v-if='areaSelected == 1')
                        .p-grid.p-justify-center
                            .p-col-12
                                .p-grid.p-fluid
                                    .p-col-12.p-md-3
                                        Button(label='Pedido de exame' @click="loadDocument({ tipo: 'PE', text: getTemplate('pedidoexame') }); item_clicado = true; tipo_clicado = 'EX';")
                                    .p-col-12.p-md-3
                                        Button(label='Receita Manual' @click="loadDocument({ tipo: 'RE', text: getTemplate('receita') }); item_clicado = true; tipo_clicado = 'RE';")
                                    .p-col-12.p-md-3
                                        IntegracaoNexoData(
                                            v-show="$store.getters.getPlataformaMemedPronta"
                                            :infoNexodata='infoNexodata'
                                            :referencia='referencia'
                                            :idGuia='idGuiaEletronica'
                                            )
                                        Button(v-if="! $store.getters.getPlataformaMemedPronta" label='...' disabled='true')
                                    .p-col-12.p-md-3
                                        Button(label='Atestado' @click="loadDocument({ tipo: 'AM', text: getTemplate('atestado') })")
                                    .p-col-12.p-md-3
                                        Button(label='Encaminhamento/Parecer' @click="loadDocument({ tipo: 'PM', text: getTemplate('encaminhamento') })")
                                    .p-col-12.p-md-3 
                                        Button(label='Selecionar Documento' @click="loadDocument({ tipo: 'UP' })")
                                    .p-col-12.p-md-3 
                                        Button(label='Ficha de avaliação pré-anestésica' @click="loadDocument({ tipo: 'TA' })")
                                    .p-col-12.p-md-3 
                                        Button(label='Termo consentimento pré-anestésica' @click="loadDocument({ tipo: 'TC' })")

                        .listSent-wrapper
                            
                            ProgressBar(v-if='waitingAnexos' mode="indeterminate")
                            Panel.p-col-12(v-else header='Documentos Enviados' :toggleable='true' :collapsed='!listSent.length')
                                div(v-if='! listSent.length')
                                    p.ta-center <b>Nenhum documento foi enviado.</b>
                                .file-container(v-else)
                                    .file-item(v-for='anexo, idx in listSent' :key='idx' :class="anexo.ie_inativo?'a-inactive':''"
                                        v-tooltip.top="anexo.ie_inativo?'anexo inativo':''")
                                        Button.p-button-raised.button-file.p-button-text(icon="jam jam-attachment" type="button" :label="`${options.document[anexo.ie_tipo_anexo]} ${anexo.dt_criado_f}`" @click='openDocument({ id: anexo.id, text: anexo.ds_anexo, fileSrc: anexo.aq_anexo })')
                                        Button.p-button-raised.p-button-info.p-button-text(v-show="!anexo.ie_inativo" icon="jam jam-printer-f" type="button" @click='openDialog(anexo)')
                                        Button.p-button-danger.aux1(v-show="!anexo.ie_inativo" icon='jam jam-close' @click='showModalRemoverDocumento(anexo)')

                            .p-col-12.ta-center(v-if='listSent.length')
                                Button(class="p-button-warning" icon="jam jam-key-f" label="Enviar documentos"
                                    @click="dialogAutenticar=true")

                    .area2(v-if='areaSelected == 2')
                        div(v-if='document.id')
                            iframe.iframeAttachment(:src='document.fileSrc')
                        div(v-else-if='document.tipo == "UP"' style="display: flex; align-items: center; justify-content: center;")
                            div.p-col-6
                                .notepad-aux.p-grid.p-fluid.p-align-center
                                    .p-col-2.p-md-1.ta-center
                                        span.jam.jam-info
                                    .p-col-10.p-md-11
                                        p Utilize essa área para enviar documentos PDF ao paciente.
                                FilePond.filepond(
                                    ref='documentoPdf'
                                    name='aq_anexo'
                                    accepted-file-types="application/pdf"
                                    maxFileSize='10MB'

                                    label-idle='Selecionar arquivo...'
                                    labelFileProcessing='Enviando'
                                    labelTapToCancel='toque para cancelar'
                                    labelTapToRetry='toque para tentar novamente'
                                    labelFileProcessingError='Erro durante o envio'
                                    labelFileTypeNotAllowed='Arquivo inválido'
                                    fileValidateTypeLabelExpectedTypes='Deve ser formato PDF'
                                    labelMaxFileSizeExceeded='Arquivo muito grande'
                                    labelMaxFileSize='O tamanho máximo é {filesize}'
                                    labelFileProcessingComplete='Envio concluído'
                                    labelTapToUndo='Toque para remover'

                                    :allow-multiple="true"
                                    :allowFileSizeValidation='true'
                                    :instantUpload='true'
                                    :server='filePondServer'
                                    )                   
                        div(v-else-if='document.tipo == "TA"')
                            p <b>Ficha de avaliação pré anestésica</b>
                            FichaAvaliacaoPreAnestesica(v-on:submit_ficha_avaliacao="_handleSubmitedAvaliacaoPreAnestesica", :patientName="patientName" :patientAge="patientAge" :cd_guia_eletronica="cd_guia_eletronica")
                        div(v-else-if='document.tipo == "TC"')
                            p <b>Termo consentimento pré anestésica</b>
                            FichaConsentimentoPreAnestesica(v-on:submit_ficha_avaliacao="_handleSubmitedConsentimentoPreAnestesica" :patientName="patientName" :patientAge="patientAge" :cd_guia_eletronica="cd_guia_eletronica")
                        div(v-else)
                            p <b>Documento: </b>{{ options.document[document.tipo] }}
                            ckeditor(:editor="editor" id="body" v-model="document.text" :config="editorConfig")
                        .p-grid.p-align-center.mt-1
                            .p-col-6
                                Button.p-button-warning(@click='areaSelected = 1; item_clicado = false; tipo_clicado = ""; ' label="Voltar para opções de documento")
                            .p-col-6.ta-right.actions-wrapper
                                ProgressSpinner(v-if='waitingSave' strokeWidth='6')

                                Button.p-button-danger.aux1(v-else-if='document.id' icon='jam jam-trash' label='Apagar' @click='remove()')
                                Button.p-button-success.aux1(v-else-if='document.tipo != "UP"' icon='jam jam-check' label='Salvar' @click='save()')

            .p-col-12.p-md-4(v-if='item_clicado && help_doctor_ativado')
                Panel.mt-4(header='Help Doctor' :toggleable='windowInnerWidth < 576' :collapsed='windowInnerWidth < 576')
                    .p-grid.p-fluid
                        .p-col-12
                            ProgressBar(v-if='waitingProtocolos' mode="indeterminate")
                            Tree(:value="nodes" selectionMode="multiple" :selectionKeys.sync="selectedKey1" @node-select="onNodeSelect" )

</template>

<style lang="scss">
.atendimento-anexos {
    .spinner {
        border: 8px solid rgba(0, 0, 0, 0.1);
        border-left-color: #22a6b3;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }

    .waiting {
        .p-progress-spinner {
            width: 60px;
            height: auto;
        }
    }
    .area1 {
        padding-top: 20px;
    }
    .link {
        text-decoration: underline;
        color: #444;
        font-size: 12px;
        font-weight: 700;
    }
    .actions-wrapper {
        .p-progress-spinner {
            width: 29px;
            height: auto;
        }
    }
    .ql-editor.overwrite {
        white-space: normal !important;
        margin: 0 auto !important;
        margin-top: 16px !important;
        background-color: #eee !important;
        padding: 10px !important;
        max-width: 400px !important;
    }
    .iframeAttachment {
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
        border: none;
        min-height: 500px;
        display: inherit;
    }
    .text-title {
        font-size: 13px;
    }
    i.jam {
        font-size: 14px;
        color: #444;
    }
    .list1 {
        list-style: none;
        li {
            margin-left: -20px;
        }
    }
    .form-label {
        font-size: 12px;
    }
    .link1 {
        margin-left: 6px;
        text-decoration: underline !important;
        display: inline-block;
        padding: 4px 6px;
        cursor: pointer;
        color: #444;
        font-size: 13px;
        &:hover {
            text-decoration: underline !important;
        }
    }
    .listSent-wrapper {
        border-top: 1px solid #ddd;
        margin-top: 20px;
        padding-top: 20px;
    }
    .filepond {
        cursor: pointer;
        border: 1px solid #c8c8c8;
        border-radius: 0.5rem;
        
        .filepond--root {
            margin-bottom: 0;
        }
    }
    .dialogAutenticar {
        width: 98%;
        max-width: 400px;
    }
    .spinnerAutenticar {
        width: 64px;
        height: auto;
    }
    .logoSoluti-wrapper {
        font-size: 0;
        text-align: center;
        img {
            max-width: 240px;
        }
    }
    .button-receita {
        border: none;
        background-color: #1867c0;
    }
    .file-container {
        display: flex;
        flex-wrap: wrap;

        .file-item {
            border:1px solid #c8c8c8; 
            border-radius: .25rem;
            margin: 7.5px 5px;
            padding: 0px;
            overflow: hidden;

            &.a-inactive {
                .button-file{
                    background-color: #9b9a9a;
                    border: 1px solid #9b9a9a;

                    &:hover{
                        background-color: #747373;
                        border: 1px solid #747373;
                    }
                }
            }

            button {
                border-radius: 0px;
            }

            .button-file{
                background-color: #e6e4e4;
                color: #333333;
                border: 1px solid #e6e4e4;

                &:hover{
                    background-color: #c9c8c8;
                    border: 1px solid #c9c8c8;
                }
            }
        }
    }
}
</style>

<script>
import Panel from "primevue/panel";
import ProgressBar from "primevue/progressbar";
import ProgressSpinner from "primevue/progressspinner";
import Button from "primevue/button";
import Editor from "primevue/editor";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import InputMask from "primevue/inputmask";
import InputSwitch from "primevue/inputswitch";
import Tree from "primevue/tree";
import ClassicEditor from "./../../assets/ckeditor5/build/ckeditor"; // https://ckeditor.com/ckeditor-5/online-builder/
import wsConfigs from "./../../middleware/configs";
import {Anexo} from "./../../middleware";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import vueFilePond from "vue-filepond";
// import IntegracaoMemed from "./IntegracaoMemed";
import IntegracaoNexoData from "./IntegracaoNexoData";
import Tooltip from 'primevue/tooltip';
import FichaAvaliacaoPreAnestesica from "./components/FichaAvaliacaoPreAnestesica.vue";
import FichaConsentimentoPreAnestesica from "./components/FichaConsentimentoPreAnestesica.vue";

const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginFileValidateSize
);
let usuario = JSON.parse(localStorage.getItem("usuario"));

import moment from "moment";
import { AtendimentoMedico, Protocolo } from "./../../middleware"; // eslint-disable-line
import { required, minLength, email } from "vuelidate/lib/validators";
import { validateCpf } from "./../../utils";

// import Memed from "./../../middleware/controllers/Memed";
import { mapGetters } from "vuex";
import configs from './../../middleware/configs'
import NexoData from '../../middleware/controllers/NexoData';

export default {
    components: {
        Panel,
        Tree,
        ProgressBar,
        Button,
        Dialog,
        Editor,
        ProgressSpinner,
        InputText,
        InputSwitch,
        InputMask,
        FilePond,
        IntegracaoNexoData,
        Tooltip,
        FichaAvaliacaoPreAnestesica,
        FichaConsentimentoPreAnestesica
    },
    directives: { tooltip: Tooltip },
    props: {
        patientName: String,
        phone: String,
        patientAge: String,
        cd_guia_eletronica: String,
        enderecoPaciente: Object
    },
    watch: {
        "$parent.model": function(model) {
            if (model) {
                this.nr_cpf = model.nr_cpf_especialista;
                if (model.guiaeletronica_set[0]) {
                    this.model = model;
                    this.ds_destinatario = model.ds_paciente_email;
                    this.idGuiaEletronica = model.guiaeletronica_set[0].id;
                    this.getAnexos();
                }
            }
        },
        idGuiaEletronica() {
            this.waitingGuia = false;
        },
        areaSelected: function(val) {
            if (val == 1) this.getAnexos();
        },
        prescricaoEmitida(val) {
            if (val) {
                this.getAnexos();
                this.getDadosNexodata();
                this.$store.commit("setPrescricaoEmitida", false);
            }
        },
        // prescricaoRemovida(val) {
        //     if (val) {
        //         this.getAnexos();
        //         this.$store.commit("setPrescricaoRemovida", false);
        //     }
        // },
        // plataformaMemedFechada(val) {
        //     if (val) {
        //         this.$store.commit("setPlataformaMemedFechada", false);
        //     }
        // }
    },
    computed: {
        phoneRaw() {
            //Telefone sem pontuação e espaços
            let phoneClean = this.phone
                .replace("55", "")
                .replace("(", "")
                .replace(")", "")
                .trim();

            return phoneClean;
        },
        ...mapGetters({
            prescricaoEmitida: "getPrescricaoEmitida"
        }),
        ...mapGetters({
            prescricaoRemovida: "getPrescricaoRemovida"
        }),
        ...mapGetters({
            plataformaMemedFechada: "getPlataformaMemedFechada"
        }),
        cidade() {
            return this.enderecoPaciente.nm_cidade
        },
        endereco() {
            return `${this.enderecoPaciente.nm_rua}, ${this.enderecoPaciente.nr_numero}`
        }
    },
    validations() {
        let v = {
            otp_token: { required, minLength: minLength(2) },
            nr_cpf: { validateCpf },
            ds_destinatario: { email, required }
        };

        return v;
    },
    created() {
        this.getDadosNexodata();

        /*
			let especialidades = []
			const distinct = (value, index, self) => {
				return self.indexOf(value) === index;
			}
			this.waitingProtocolos = true
			Protocolo.findAll().then(response => {
				this.waitingProtocolos = false
				if(response.status == 200) {
					var options = []
					response.data.forEach(data => {
						especialidades.push(data.nm_especialidade);
						options.push({
							key: data.id,
							icon: 'jam jam-document',
							label: data.ds_titulo,
							data: data.ds_protocolo,
							especialidade: data.nm_especialidade,
							tipo: data.ie_tipo,
						})
					})
					especialidades.filter(distinct).map((item, index) => {
						this.nodes.push(
							{
								key: index,
								label: item,
								icon: 'jam jam-archive',
								children: options.filter(i => i.especialidade == item)
							})

					})
				}

			})
			*/
    },
    data() {
        return {
            model: {},
            options: {
                document: {
                    PE: "Pedido de exame",
                    RE: "Receita",
                    AM: "Atestado médico",
                    PM: "Encaminhamento/Parecer médico",
                    TA: "Ficha de avaliação pré-anestésica",
                    TC: "Termo de consentimento - pré-anestésica"
                }
            },
            nodes: [],
            editor: ClassicEditor,
            ficha_avalicao_anestesica: null,
            ficha_consentimento_anestesica: null,
            editorConfig: {
                toolbar: [
                    "bold",
                    "italic",
                    "|",
                    "bulletedList",
                    "numberedList",
                    "|",
                    "alignment:left",
                    "alignment:right",
                    "alignment:center",
                    "alignment:justify"
                ]
            },
            otp_token: "",
            nr_cpf: "",
            ds_destinatario: "",
            selectedKey1: null,
            ie_assinar: true,
            referencia: null,
            windowInnerWidth: window.innerWidth,
            document: {},
            waitingProtocolos: false,
            textoProtocolo: "",
            item_clicado: false,
            help_doctor_ativado: false,
            tipo_clicado: "",
            lista: [],
            dialogAutenticar: false,
			dialogoImprimir: false,
            dialogRemover: false,
            idGuiaEletronica: 0,
            waitingGuia: true,
            tokenMemed: "",
            cpfEspecialista: "",
            listSent: [],
            waiting: false,
            waitingSave: false,
            waitingAnexos: false,
            waitingToken: false,
            infoNexodata: {},
            areaSelected: 1,
			anexo: null,
			anexo2guias: null,
            submitted: false,
            documentoASerRemovido: {},
            documentoASerRemovidoMotivo: '',
			anexoASerImpresso: null,
			loadingDialogButton:false,
            cidsList() {
                let text = "";
                this.$parent.$refs.prontuarioComponent.selectedCIDS.forEach(
                    cid => {
                        if (cid.nr_cid) {
                            if (text.length) text += ", ";
                            text += cid.nr_cid;
                        }
                    }
                );
                return text;
            },
            filePondServer: {
                process: {
                    url: `${wsConfigs.baseUrl}/webcli/anexo/`,
                    method: "POST",
                    headers: {
                        Authorization: `${usuario.tokenType} ${usuario.accessToken}`
                    },
                    ondata: formData => {
                        formData.append(
                            "cd_guia_eletronica",
                            this.idGuiaEletronica
                        );
                        formData.append("ie_tipo_anexo", "RE");
                        formData.append("nm_anexo", "Documento");
                        formData.append(
                            "cd_estabelecimento",
                            usuario.estabelecimentos[
                                usuario.idxCurrentEstabelecimento
                            ].id
                        );
                        return formData;
                    },
                    onload: () => {
                        this.getAnexos();
                    }
                },
                revert: (uniqueFileId, load) => {
                    let result = JSON.parse(uniqueFileId);
                    AtendimentoMedico.removeAnexo(result.id).then(response => {
                        if (response.status == 200)
                            this.$toast.success("Documento removido", {
                                duration: 3000
                            });
                        load();
                    });
                }
            },
            headerPdfPanel() {
                let header = "";
                if (this.model.nm_conselho == "CRN")
                    header = "Anexar plano alimentar";
                else if (this.model.nm_conselho == "CRM")
                    header = "Selecionar documento";
                return header;
            }
        };
    },
    methods: {
        _handleSubmitedAvaliacaoPreAnestesica(val) {
            this.ficha_avalicao_anestesica = val;
            this.ficha_avalicao_anestesica["nome_medico"] = this.model.ds_especialista;
        },
        _handleSubmitedConsentimentoPreAnestesica(val) {
            this.ficha_consentimento_anestesica = val;
            this.ficha_consentimento_anestesica["medico_nome"] = this.model.ds_especialista;
            this.ficha_consentimento_anestesica["medico_crm"] = this.model.nr_conselho;
        },
        assinarDocumentos() {
            this.submitted = true;

            if (this.ie_assinar == true) {
                this.$v.$touch();
                if (this.$v.$invalid) return 0;
            }

            let dataSend = {
                username: this.nr_cpf.match(/\d+/g).join(""),
                password: this.otp_token,
                ds_destinatario: this.ds_destinatario,
                cd_guia_eletronica: this.idGuiaEletronica,
                ie_assinar: this.ie_assinar
            };
            if (dataSend["ds_destinatario"] == "")
                delete dataSend["ds_destinatario"];

            this.waiting = true;

            AtendimentoMedico.assinarDocumentos(dataSend).then(response => {
                this.waiting = false;
                if ([200, 201, 204].includes(response.status)) {
                    this.$toast.success("Documento enviado com sucesso", {
                        duration: 3000
                    });
                    this.dialogAutenticar = false;
                } else if (response.data.detail) {
                    if (typeof response.data.detail == "string")
                        this.$toast.error(response.data.detail, {
                            duration: 3000
                        });
                    else
                        response.data.detail.forEach(msg =>
                            this.$toast.error(msg, { duration: 3000 })
                        );
                }
            });
        },
        onNodeSelect(node) {
            // console.log(node);
            if (node.children == null || node.children == undefined) {
                if (this.textoProtocolo !== "") {
                    if (this.tipo_clicado == "RE") {
                        this.textoProtocolo = this.textoProtocolo.concat(
                            "<br>" + node.label
                        );
                        this.document.text = this.getTemplate("receita");
                    }
                    if (this.tipo_clicado == "EX") {
                        this.textoProtocolo = this.textoProtocolo.concat(
                            "<br>" + node.label
                        );
                        this.document.text = this.getTemplate("pedidoexame");
                    }
                } else {
                    if (this.tipo_clicado == "RE") {
                        this.textoProtocolo = node.label;
                        this.document.text = this.getTemplate("receita");
                    }
                    if (this.tipo_clicado == "EX") {
                        this.textoProtocolo = node.label;
                        this.document.text = this.getTemplate("pedidoexame");
                    }
                }
            }
            //this.document.text = '';
        },
        refresh() {
            if (!this.document.id && this.document.tipo == "AM")
                this.loadDocument({
                    tipo: "AM",
                    text: this.getTemplate("atestado")
                });
        },
        async save() {
            if(this.document.tipo == "TA"){
                const {data} = await Anexo.save_termo_avaliacao_anestesica({params: this.ficha_avalicao_anestesica});
                this.document.text = data;
            }
            if(this.document.tipo == "TC"){
                const {data} = await Anexo.save_termo_consentimento_anestesica({params: this.ficha_consentimento_anestesica});
                this.document.text = data;
            }

            if (!this.document.text.length) {
                this.$toast.error("Texto não pode ser vazio", {
                    duration: 3000
                });
                return 0;
            }
            this.waitingSave = true;
            AtendimentoMedico.saveAnexo(
                this.idGuiaEletronica,
                this.document.tipo,
                this.document.text
            ).then(response => {
                this.waitingSave = false;
                if ([200, 201].includes(response.status)) {
                    this.$toast.success("Documento anexado ao atendimento", {
                        duration: 3000
                    });
                } else if (response.status != 500)
                    this.$toast.error("Erro ao salvar documento", {
                        duration: 3000
                    });
                this.areaSelected = 1;
            });
        },
        //Primeiro o Modal é mostrado, apenas depois que a função de remover é chamada
        showModalRemoverDocumento(doc) {
            this.documentoASerRemovido = doc;
            this.dialogRemover = true;
        },
		showModalImprimirAnexo(id){
			this.anexoASerImpresso = id
			this.dialogImprimir = true;
		},
        showModalRemoverMemed(index) {
            this.index.splice(index, 1);
        },
        async remove() {
            let doc = this.documentoASerRemovido;
            doc.ds_motivo_inativo = this.documentoASerRemovidoMotivo;
            // O modal deve parar de ser exibido antes do documento ser removido
            this.dialogRemover = false;

            //this.waitingSave = true
            this.waitingAnexos = true;
            if(!doc.cd_receita_nexodata) {
                AtendimentoMedico.removeAnexo(doc.id, doc.ds_motivo_inativo).then(response => {
                    //this.waitingSave = false
                    if ([200, 201].includes(response.status)) {
                        this.$toast.info("Documento removido do atendimento", {
                            duration: 3000
                        });
                        this.areaSelected = 1;
                    } else if (response.status != 500)
                        this.$toast.error("Erro ao remover documento", {
                            duration: 3000
                        });

                    this.getAnexos();
                });
            } else {
                 if (doc.ie_tipo_anexo === "RE") {
                    // Memed.apagarReceita(this.tokenMemed, doc.id_prescricao_memed);
                    // console.log(doc);

                    let apagar = await NexoData.cancelarPrescricao(configs.nexoData.AUTH_TOKEN, doc.cd_receita_nexodata.id_nexodata)

                    if(apagar.status == 200) {
                        const params = {
                            id_nexodata: doc.cd_receita_nexodata.id_nexodata,
                            cd_guia_eletronica: this.idGuiaEletronica
                        }

                        //Salva a receita na API
                        AtendimentoMedico.savePrescricaoNexoData(params).then(() => {
                            //Insere no Store de que a receita já está na API e o carregamento pode terminar
                            this.getAnexos();
                        })
                    }else if([400, 404].includes(apagar.status)){
                        this.$toast.error("Erro ao remover documento", {duration: 3000});
                    }

                }
            }
            this.waitingAnexos = false;
            //Caso seja uma receita memed apagar no sistema memed tb
        },
        getAnexos() {
            this.waitingAnexos = true;
            AtendimentoMedico.getAnexos(this.idGuiaEletronica).then(response => {
                this.waitingAnexos = false;
                this.listSent = [];
                if (response.status == 200) {
                    response.data.forEach(anexo => {
                        if (anexo.dt_criado)
                            anexo.dt_criado_f = moment(anexo.dt_criado).format("DD/MM/YYYY - HH:mm:ss");
                        if (
                            ["PE", "RE", "AM", "PM", "TA", "TC"].includes(
                                anexo.ie_tipo_anexo
                            )
                        )
                            this.listSent.push(anexo);
                    });
                }
                this.listSent.sort((a,b)=> a.dt_criado > b.dt_criado ? 1 : -1 )
            });
        },
        getDadosNexodata() {
            this.waitingToken = true;
            AtendimentoMedico.getDadosNexoData(this.$route.params.id).then(response => {
                this.waitingToken = false;
                this.infoNexodata = response.data;
                if(response.data.referencia) this.referencia = response.data;
            });
        },
        openDocument(document) {
            let w = window.open(document.fileSrc, "_blank");
            w.focus();
        },
		openDialog(anexo){
			if(anexo.ie_tipo_anexo == "RE"){
				this.dialogoImprimir=true;
				this.anexoASerImpresso = anexo.id;
			}else{
				this.printDocument(anexo.id)
			}

		},
		printDocument(id) {
			AtendimentoMedico.imprimirReceita(id, {ie_duas_vias: false}).then(async response =>{
				if(response.status == 200){
					this.dialogoImprimir = false;
					this.loadingDialogButton = false
					let blob = new Blob([response.data], { type: 'application/pdf; charset=utf-8' })
					var fileURL = URL.createObjectURL(blob);
					let a = window.open(fileURL);
					a.print();

				}
			})
		},
		print2Documents(id) {
			AtendimentoMedico.imprimirReceita(id, {ie_duas_vias: true}).then(async response =>{
				if(response.status == 200){
					this.dialogoImprimir = false;
					this.loadingDialogButton = false
					let blob = new Blob([response.data], { type: 'application/pdf; charset=utf-8' })
					var fileURL = URL.createObjectURL(blob);
					let a = window.open(fileURL);
					a.print();
				}
			})
		},
        loadDocument(document) {
            this.document = document;
            this.areaSelected = 2;
        },
        getTemplate(key) {
            let templates = {
                pedidoexame: `
						<p style='text-align:center'>
							<strong>PEDIDO DE EXAME</strong>
						</p>
						<p>${this.textoProtocolo}</p>
						<p><br><br><br></p>
						<p style='text-align:right'>Dr(a). ${this.model.ds_especialista} <br />(${
                    this.model.nm_conselho
                } ${this.model.nr_conselho} ${this.model.uf_conselho})</p>
						<p><br></p>
						<p style='text-align:right'>Teresina, <strong>${moment().format(
                            "DD/MM/YYYY"
                        )}</strong></p>
					`,

                receita: `
						<p style='text-align:center'>
							<strong>RECEITA MÉDICA</strong>
						</p>
						<p>${this.textoProtocolo}</p>
						<p><br><br><br></p>
						<p style='text-align:right'>Dr(a). ${this.model.ds_especialista} <br />(${
                    this.model.nm_conselho
                } ${this.model.nr_conselho} ${this.model.uf_conselho})</p>
						<p><br></p>
						<p style='text-align:right'>Teresina, <strong>${moment().format(
                            "DD/MM/YYYY"
                        )}</strong></p>
					`,

                atestado: `
						<p style='text-align:center'>
							<strong>ATESTADO MÉDICO</strong>
						</p>
						<p>${this.textoProtocolo}</p>
						<p><br><br><br></p>
						<p>Atesto para os devidos fins que o(a) Sr(a). <strong>${
                            this.model.nm_paciente
                        }</strong> compareceu a esta Unidade Médica, onde verificamos:</p>
						<p><br><br><br></p>
						<p><strong>CID(s):</strong> ${this.cidsList()}</p>
						<p>A impossibilidade de trabalhar por: <strong>1 dia(s)</strong>, a partir desta data.</p>
						<p><br><br><br></p>
						<p style='text-align:right'>Dr(a). ${this.model.ds_especialista} <br />(${
                    this.model.nm_conselho
                } ${this.model.nr_conselho} ${this.model.uf_conselho})</p>
						<p><br></p>
						<p style='text-align:right'>Teresina, <strong>${moment().format(
                            "DD/MM/YYYY"
                        )}</strong></p>
					`,

                encaminhamento: `
						<p style='text-align:center'>
							<strong>ENCAMINHAMENTO MÉDICO</strong>
						</p>
						<p><br><br></p>
						<p><strong>CID(s):</strong> ${this.cidsList()}</p>
						<p style='text-align:right'>Dr(a). ${this.model.ds_especialista} <br />(${
                    this.model.nm_conselho
                } ${this.model.nr_conselho} ${this.model.uf_conselho})</p>
						<p><br></p>
						<p style='text-align:right'>Teresina, <strong>${moment().format(
                            "DD/MM/YYYY"
                        )}</strong></p>
					`
            };

            return templates[key] ? templates[key] : "";
        }
    }
};
</script>
