<template lang="pug">
    div.mb-2
        .ta-center.waitingComorbidades(v-if='waiting')
            ProgressSpinner(strokeWidth='2')
        .atendimento-comorbidades.p-1(v-else)
            h4.p-0.ma-1 Comorbidades do Paciente
            div.d-flex.align-itens-center.pa-1(v-for="comorbidade in comorbidades")
                Checkbox.mr-1(name="comob" v-model="model.comorbidades" :value="comorbidade.id"  :id="`com_${comorbidade.id}`" v-if="!comorbidade.ie_resposta_texto")
                label(:for="`com_${comorbidade.id}`") {{comorbidade.ds_comorbidade}}
                inputText.ml-2.flex-1(v-if="comorbidade.ie_resposta_texto" v-model="model.comorbidades_txt[comorbidade.id]")
            div.ta-right
                Button.ta-right(@click="atualizaComorbidades()" label="Atualizar Ficha")
</template>

<style lang="scss">
    .atendimento-comorbidades {
        .d-flex{
            display:flex;
        }
        .align-itens-center{
            align-items:center
        }
        .flex-1{
            flex:1
        }
        .img-anexo {
            max-width: 100%;
            max-height: 640px;
        }
        .img-thumb {
            display: block;
            max-width: 100px;
            max-height: 100px;
        }
        .waitingComorbidades {
            .p-progress-spinner {
                margin-top: 20%;
                width: 60px;
                height: auto;
            }
        }
    }
</style>

<script>
    import Panel from 'primevue/panel'
    import Button from 'primevue/button'
    import inputText from 'primevue/inputtext'
    import Checkbox from 'primevue/checkbox';
    import ProgressSpinner from 'primevue/progressspinner';
    

    import { AtendimentoMedico } from '../../middleware'
    export default {
        components: { Panel, Button, inputText, Checkbox, ProgressSpinner },
        created () {
            this.idGuiaEletronica = this.$parent.$parent.idGuiaEletronica
            this.idPaciente = this.$parent.$parent.$parent.model.cd_paciente
            this.loadComorbidade()
        },
        data () {
            return {
                comorbidades: [],
                waiting: false,
                model:{
                    comorbidades: [],
                    comorbidades_txt: {},
                }
            }
        },
        methods: {
            loadComorbidade () {
                this.waiting = true
                AtendimentoMedico.getComorbidadesPaciente(this.idGuiaEletronica, this.idPaciente).then(response => {
                    if (response.status == 200) {
                        response.data.forEach( (item) => {
                            if( item.ie_resposta == 'S' )
                                this.model.comorbidades.push(item.cd_comorbidade)

                            this.model.comorbidades_txt[item.cd_comorbidade] = item.ds_resposta
                        } )
                        console.log(this.model.comorbidades)
                        AtendimentoMedico.getComorbidades().then( response => {
                            if (response.status == 200) {
                                this.comorbidades = response.data
                            }
                            this.waiting = false
                        } )
                        
                    } else this.waiting = false
                })
            },
            atualizaComorbidades(){
                
                let dataSend = {}
                dataSend['cd_paciente'] = this.idPaciente
                dataSend['respostas'] = []
                this.model.comorbidades.forEach( item => {
                    let resp = {
                        'ie_resposta': 'S',
                        'cd_comorbidade': item,
                    }
                    let found = this.comorbidades.find( el => el.id == item )
                    if( found.ie_resposta_texto )
                        resp['ds_resposta'] = this.model.comorbidades_txt[item]
                    dataSend['respostas'].push(resp)
                })
                
                this.waiting = true
                AtendimentoMedico.atualizaComorbidadesPaciente(this.idGuiaEletronica, this.idPaciente, dataSend).then(response => {
                    console.log(response)
                    this.waiting = false
                })
            }
        }
    }
</script>