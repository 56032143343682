<template lang="pug">
	.atendimento-videostream
		#teleconsultaContainer

		Dialog.dialogFecharChamada(header='Desligar chamada' :visible.sync='dialogFecharChamada' :modal='true')
			p.ta-center Deseja desligar a chamada?
			.ta-center.my-4
				Button(label='Desligar chamada' icon='jam jam-pause' @click='fecharChamada()')

		div(v-if='$parent.guiaExecutada')
			.not-bubble.mt-4
				.ta-center(v-if='$parent.guiaExecutada && ! $parent.atendimentoFinalizado')
					Button.p-button-lg.button-finalizar(v-if='chamadaAberta' label='Desligar chamada' icon='jam jam-pause' @click='dialogFecharChamada = true')
					div(v-else-if='firstOpen' style='display: inline')
						Button.p-button-lg.button-finalizar(label='Retornar' icon='jam jam-log-out' @click="$router.push({ name: 'atendimento-medico' })")
						Button.p-button-lg.button-finalizar.ml-2(label='Reabrir chamada' icon='jam jam-play' @click='executarGuia')
					Button.p-button-danger.p-button-lg.button-finalizar.ml-2(v-if='firstOpen' label='Finalizar consulta' icon='jam jam-power' @click='$parent.dialogFinalizar = true')

		.box-button(v-else-if='$parent.model.id')
			ProgressSpinner(v-if='waitingGuia' strokeWidth='2')
			Button.p-button-lg.button-atender.mt-4(v-else label='Iniciar chamada por vídeo' icon='jam jam-play-circle' @click='executarGuia')
</template>

<style lang="scss">
	$heightIsomeet: 490px;
	#teleconsulta {
		#dd_isomeet {
			position: absolute;
			top: -$heightIsomeet;
			right: 0;
			bottom: 0;
			left: 0;
			height: $heightIsomeet;
			width:92%;
			@media all and (min-width: 576px) {
				left: 4%;
			}
			@media all and (max-width: 575px) {
				width:100%;
			}
			z-index: 998;
			&.bubble {
				position: fixed;
				max-width: 360px;
				@media all and (max-width: 576px) {
					left: auto;
					width: 160px;
					height: 200px;
					right: 10px;
					top: 10px;
				}
				left: auto;
				width: 360px;
				height: $heightIsomeet;
				right: 10px;
				top: 10px;
			}
			iframe {
				height: 100%;
			}
		}
	}
	.em-atendimento .main-wrapper {
		position: absolute;
		top: $heightIsomeet;
		&.alt { top: $heightIsomeet / 3; }
		&.alt2 { top: 0; }
		left: 0;
		right: 0;
		padding-bottom: 60px;
		min-height: 400px;
		@media all and (min-width: 576px) {
			max-width: 80%;
		}
		&.bubble {
			top: $heightIsomeet;
		}
		.touchable {
			position: static;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
		.not-bubble {
			position: relative;
			top: -28px;
		}
		.box-button {
			position: absolute;
			top: -$heightIsomeet / 8 - 50;
			left: 0;
			width: 100%;
			text-align: center;
		}
		.button-atender {
			font-size: 18px;
			font-weight: 700;
		}
	}
	.em-atendimento {
		.menubar-wrapper, .main-footer {
			display: none;
		}
	}
</style>

<script>
	import Button from 'primevue/button'
	import ProgressSpinner from 'primevue/progressspinner'
	import Dialog from 'primevue/dialog'

	import { Agendamento } from './../../middleware'
    import wsConfigs from '../../middleware/configs'
	export default {
		components: { Button, ProgressSpinner, Dialog },
		watch: {
			'$parent.model': function (model) {
				if (model) {
					let mainWrapper = document.querySelector('.em-atendimento .main-wrapper')
					mainWrapper.classList.add('alt')
					this.model = model
				}
			}
		},
		destroyed () {
			if (this.isomeetScriptExists) document.body.removeChild(this.isomeetScript)
			window.removeEventListener('scroll', this.handleScroll)
		},
		data () {
			return {
				isomeetScript: null,
				isomeetScriptExists: false,
				waitingGuia: false,
				dialogFecharChamada: false,
				chamadaAberta: false,
				firstOpen: false
			}
		},
		methods: {
			handleScroll () {
				let isoMeet = document.querySelector('#dd_isomeet')
				let notBubble = document.querySelector('.not-bubble')
				if (isoMeet) {
					let mainWrapper = document.querySelector('.em-atendimento .main-wrapper')
					if (window.scrollY > 400) {
						isoMeet.classList.add('bubble')
						mainWrapper.classList.add('bubble')
						notBubble.setAttribute('style', 'display: none')
						this.draggable(true)
					} else {
						isoMeet.removeAttribute('style')
						isoMeet.classList.remove('bubble')
						mainWrapper.classList.remove('bubble')
						notBubble.removeAttribute('style')
						this.draggable(false)
					}
				}
			},
			executarGuia () {
				this.waitingGuia = true
				Agendamento.executarGuiaMedico(this.model.id).then(response => {
					this.waitingGuia = false
					if (response.status == 200) {
						
						this.$parent.guiaExecutada = true
						this.$parent.atendimentoFinalizado = false

						this.abrirChamada()
						
					} else if (response.status == 404)
						this.$toast.error('Esta consulta já foi encerrada pelo médico', { duration: 3000 })
				})
			},
			abrirChamada () {

				let elContainer = document.getElementById('teleconsultaContainer')
				let teleconsulta = document.createElement('DIV')
				teleconsulta.id = 'teleconsulta'
				elContainer.appendChild(teleconsulta)

				this.isomeetScript = document.createElement('script')
				//this.isomeetScript.setAttribute('src', 'https://isomeet.app/widget.js?key=medclubapp&channel=1')
                this.isomeetScript.setAttribute(
                   "src",
                  wsConfigs.video_call_iframe_url
                 )
				this.isomeetScript.onload = () => {
					let mainWrapper = document.querySelector('.em-atendimento .main-wrapper')
					mainWrapper.classList.remove('alt')
					mainWrapper.classList.remove('alt2')

					abrirReuniao(this.model.guiaeletronica_set[0].ds_url_zoom, 'teleconsulta') // eslint-disable-line
					window.addEventListener('scroll', this.handleScroll)

					let isoMeetHeader = document.querySelector('#dd_isomeet .header')
					let toucheableDiv = document.createElement('DIV')
					toucheableDiv.classList.add('touchable')
					isoMeetHeader.appendChild(toucheableDiv)
					this.chamadaAberta = true
					this.firstOpen = true
					this.$toast.success('Chamada aberta', { duration: 3000 })
				}
				this.isomeetScriptExists = true
				document.body.appendChild(this.isomeetScript)

			},
			fecharChamada () {

				console.log('fechando a chamada');
				Agendamento.atualizaStatus(parseInt(this.model.id),'DE').then(response => {
					if (response.status == 200) {
						this.statusChamada = response.data.ie_status_chamada != null ? this.options.statusChamada[response.data.ie_status_chamada] : "Desligado"
					}
				})

				let isoMeet = document.querySelector('#teleconsulta')
				isoMeet.parentNode.removeChild(isoMeet)

				if (this.isomeetScriptExists) document.body.removeChild(this.isomeetScript)
				window.removeEventListener('scroll', this.handleScroll)
				this.isomeetScriptExists = false
				
				let mainWrapper = document.querySelector('.em-atendimento .main-wrapper')
				mainWrapper.classList.add('alt')
				mainWrapper.classList.add('alt2')
				mainWrapper.classList.remove('bubble')
				this.chamadaAberta = false
				this.dialogFecharChamada = false
				this.$toast.success('Chamada fechada', { duration: 3000 })

			},
			draggable (enabled = true) {
				let isoMeet = document.querySelector('#dd_isomeet')
				let isoMeetTouchable = document.querySelector('#dd_isomeet .touchable')

				let ref = { x: 0, y: 0 }
				let track = false

				const getClient = () => {
					let client = { x: 0, y: 0 }
					if (window.event.touches)
						client = {
							x: window.event.touches[0].clientX,
							y: window.event.touches[0].clientY
						}
					else if (! isNaN(window.event.clientX))
						client = {
							x: window.event.clientX,
							y: window.event.clientY
						}
					return client
				}

				let onMove = () => {
					if (track) {
						document.body.setAttribute('style', 'overflow: hidden;')
						isoMeetTouchable.focus()
						let left = getClient().x - ref.x
						let top = getClient().y - ref.y
						ref = { x: getClient().x, y: getClient().y }
						isoMeet.setAttribute('style',
							`left: ${ isoMeet.offsetLeft + left }px !important; top: ${ isoMeet.offsetTop + top }px !important`)
					}
				}

				let onDown = () => {
					ref = { x: getClient().x, y: getClient().y }
					track = true
				}

				let onUp = () => {
					track = false
					setTimeout(() => {
						document.body.setAttribute('style', 'overflow: visible;')
					}, 250)
				}

				isoMeetTouchable.onmousedown = enabled ? onDown : () => false
				isoMeetTouchable.onmouseup = enabled ? onUp : () => false
				window.onmousemove = enabled ? onMove : () => false

				isoMeetTouchable.ontouchstart = enabled ? onDown : () => ! enabled
				isoMeetTouchable.ontouchend = enabled ? onUp : () => false
				window.ontouchmove = enabled ? onMove : () => ! enabled
			}
		}
	}
</script>