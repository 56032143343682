import axios from 'axios'
import configs from '../configs'


const cancelarPrescricao = (token, id) => {
    let url = `https://${configs.nexoData.DOMINIO_API_NEXODATA}/prescricao/${id}/`

    return axios.delete(
        `${ url }`, { headers: {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${ token }`
        } }
    )
    .then(response => response)
    .catch(err => {
        return ([401]).includes(err.response.status) ?
            configs.errorHandler(err) :
            err.response
    })
}

const iniciarPrescricao = (dataSend) => {
    let url = `https://${configs.nexoData.DOMINIO_API_NEXODATA}/prescricao/iniciar`

    return axios.post(
        `${ url }`, dataSend, { headers: {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${ configs.nexoData.AUTH_TOKEN }`
        } }
    )
    .then(response => response)
    .catch(err => {
        return ([401]).includes(err.response.status) ?
            configs.errorHandler(err) :
            err.response
    })
}

const novaSessao = (idPrescricao) => {
    let url = `https://${configs.nexoData.DOMINIO_API_NEXODATA}/prescricao/${idPrescricao}/sessao`

    return axios.post(
        `${ url }`, {}, { headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${ configs.nexoData.AUTH_TOKEN }`
            } }
    )
        .then(response => response)
        .catch(err => {
            return ([401]).includes(err.response.status) ?
                configs.errorHandler(err) :
                err.response
        })
}

export default {
    iniciarPrescricao,
    novaSessao,
    cancelarPrescricao
}
