<template>
    <div>
        <form>
            <div class="form-group">
                <label for="procedimento_cirurgico">Procedimento Cirúrgico:</label>
                <input type="text" id="procedimento_cirurgico" v-model="form.procedimento_cirurgico">
            </div>
            

            <h3>Paciente</h3>
            <div class="form-group">
                <label for="paciente_nome">Nome:</label>
                <input type="text" id="paciente_nome" v-model="form.paciente_nome">
            </div>
            <div class="form-group">
                <label for="paciente_idade">Idade:</label>
                <input type="number" id="paciente_idade" v-model="form.paciente_idade">
            </div>
            <div class="form-group">
                <label for="paciente_identidade">Identidade Nº:</label>
                <input type="text" id="paciente_identidade" v-model="form.paciente_identidade">
            </div>
            <div class="form-group">
                <label for="paciente_orgao_expedidor">Órgão Expedidor:</label>
                <input type="text" id="paciente_orgao_expedidor" v-model="form.paciente_orgao_expedidor">
            </div>
       
            <div class="form-group-radio">
                <label for="declarante_tipo" style="display: block;">Paciente tem declarante?</label>

                <label style="display: block;"><input type="radio" v-model="form.declarante_tipo" value="P">
                    Não
                </label>
                <label style="display: block;">
                    <input type="radio" v-model="form.declarante_tipo" value="R">
                    Sim
                </label>
            </div>
          

            <!-- Paciente (se diferente do declarante) -->
            <div v-if="form.declarante_tipo != 'P'">
                     <!-- Declarante -->
                    <h3>Declarante</h3>
                    <div class="form-group">
                        <label for="declarante_nome">Nome:</label>
                        <input type="text" id="declarante_nome" v-model="form.declarante_nome">
                    </div>
                    <div class="form-group">
                        <label for="declarante_idade">Idade:</label>
                        <input type="number" id="declarante_idade" v-model="form.declarante_idade">
                    </div>
                    <div class="form-group">
                        <label for="declarante_identidade">Identidade Nº:</label>
                        <input type="text" id="declarante_identidade" v-model="form.declarante_identidade">
                    </div>
                    <div class="form-group">
                        <label for="declarante_orgao_expedidor">Órgão Expedidor:</label>
                        <input type="text" id="declarante_orgao_expedidor" v-model="form.declarante_orgao_expedidor">
                    </div>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    props:['patientName', 'patientAge', 'cd_guia_eletronica'],
    data() {
        return {
            form: {
                declarante_nome: '',
                declarante_idade: '',
                declarante_identidade: '',
                declarante_orgao_expedidor: '',
                paciente_nome: this.patientName,
                paciente_idade: this.patientAge,
                paciente_identidade: '',
                paciente_orgao_expedidor: '',
                procedimento_cirurgico: '',
                declarante_tipo: 'P'
            }
        };
    },
    watch: {
        form: {
            handler: function (newVal) {
                this.$emit('submit_ficha_avaliacao', newVal);
            },
            deep: true
        }
    }
};
</script>

<style scoped>
/* Estilos opcionais para o formulário */
.form-group {
    margin-bottom: 10px;
}
.form-group-radio {
    margin-bottom: 10px;
}
.form-group-radio label:first-child {
    margin-bottom: 5px;
}

.form-group label {
    display: inline-block;
    width: 200px;
}

.form-group input {
    padding: 5px;
    width: 300px;
}
</style>