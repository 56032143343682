<template lang="pug">
.main-wrapper.tela-atendimento
    header.main-wrapper-header
        .p-grid.p-align-center
            .p-col-12.ta-right
                h1.text-header.text-secondary MedClub / <b>{{ atendimentoFinalizado && guiaExecutada ? 'Atendimento concluído' : 'Em Atendimento '}}</b>

    VideoStream(ref='videoStream' v-if='!model.detail' v-bind:url_isomeet="model")

    ProgressBar(v-if='! model' mode="indeterminate")
    div(v-else-if='model.detail')
        p.ta-center.text-secondary(style='margin-top: 40px;') {{ model.detail }}
    Panel.panel-list(v-else-if='model.nm_paciente' header='Informações do atendimento' :toggleable='false')
        p <b>Paciente:</b> <span style='font-size: 16px;'>{{ model.nm_paciente }} ({{ model.idade }} anos)</span>
        p <b>Telefone:</b> {{ $root.applyTelefoneMask(model.nr_paciente_telefone) }}
        p <b>E-mail:</b> {{ model.ds_paciente_email }}
        p <b>Especialista:</b> {{ model.ds_especialista }}
        p <b>Especialidade:</b> {{ model.ds_especialidade }}
        p <b>Data agendada:</b> {{ model.dt_agenda_f }}
        .acesso-info
            p
                span.text Médico acessou
                span.icon.jam(:class="{ 'jam-check': model.ie_medico_clicou == true, 'jam-close': model.ie_medico_clicou == false  }")
            p
                span.text Paciente acessou
                span.icon.jam(:class="{ 'jam-check': model.ie_paciente_clicou == true, 'jam-close': model.ie_paciente_clicou == false  }")
            p
                span.text Status da Chamada: {{ statusChamada }}

    Prontuario(ref='prontuarioComponent' v-if='! model.detail')
    Anexos(ref='anexosComponent' v-if='! model.detail' :patientName="model.nm_paciente" :phone="model.nr_paciente_telefone" :enderecoPaciente="model.endereco_paciente" :patientAge="String(model.idade)" :cd_guia_eletronica="model?.guiaeletronica_set?.[0].id")

    Dialog.dialogFinalizar(header='Finalizar consulta' :visible.sync='dialogFinalizar' :modal='true')
        p.ta-center Deseja finalizar a consulta?
        .ta-center.my-4
            ProgressSpinner(v-if='waitingFinalizar' strokeWidth='6')
            Button.p-button-danger(v-else label='Finalizar consulta' icon='jam jam-power' @click='finalizarAtendimento()')

    div(v-if='model.id')
        .ta-center(v-if='atendimentoFinalizado')
            p.msg-success(v-if='guiaExecutada') <i class='pi pi-thumbs-up'></i> O atendimento foi concluído!
            Button.p-button-lg.button-finalizar.mt-4(label='Retornar' icon='jam jam-log-out' @click="$router.push({ name: 'atendimento-medico' })")
            Button.p-button-lg.button-finalizar.mt-4.ml-4.p-button-warning(v-if='! guiaExecutada' label='Finalizar o atendimento' icon='jam jam-alert' @click='dialogFinalizar = true')

        .ta-center.mt-4(v-else-if='guiaExecutada && ! atendimentoFinalizado')
            Button.p-button-lg.button-finalizar(v-if='$refs.videoStream.chamadaAberta' label='Desligar chamada' icon='jam jam-pause' @click='$refs.videoStream.dialogFecharChamada = true')
            div(v-else-if='$refs.videoStream.firstOpen' style='display: inline')
                Button.p-button-lg.button-finalizar(label='Retornar' icon='jam jam-log-out' @click="$router.push({ name: 'atendimento-medico' })")
                Button.p-button-lg.button-finalizar.ml-2(label='Reabrir chamada' icon='jam jam-play' @click='$refs.videoStream.abrirChamada()')
            Button.p-button-danger.p-button-lg.button-finalizar.ml-2(label='Finalizar consulta' icon='jam jam-power' @click='dialogFinalizar = true')

</template>

<style lang="scss">
.tela-atendimento {
    .button-finalizar {
        font-size: 14px;
        font-weight: 700;
        @media all and (max-width: 576px) {
            font-size: 14px;
            margin-top: 15px;
        }
    }
    .msg-success {
        font-weight: 700;
        color: #689f38;
        margin-top: 40px;
    }
    .dialogFinalizar {
        .p-progress-spinner {
            width: 29px;
            height: auto;
        }
    }
    .panel-list {
        position: relative;
        .acesso-info {
            position: absolute;
            bottom: 12px;
            right: 12px;
            @media all and (max-width: 576px) {
                position: static;
            }
            text-align: right;
            p {
                margin: 0;
                font-size: 12px;
                font-weight: 700;
            }
            .text {
                display: inline-block;
                vertical-align: middle;
            }
            .icon {
                display: inline-block;
                vertical-align: middle;
                margin-left: 2px;
                width: 20px;
                height: 20px;
                line-height: 20px;
                text-align: center;
                font-size: 24px !important;
                font-weight: 700;
                &.jam-close {
                    color: #d33838;
                }
                &.jam-close {
                    color: #4b738d;
                }
            }
        }
    }
}
</style>

<script>
import Panel from "primevue/panel";
import ProgressBar from "primevue/progressbar";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import ProgressSpinner from "primevue/progressspinner";

import { Agendamento } from "./../../middleware";
import Prontuario from "./Atendimento.prontuario";
import VideoStream from "./Atendimento.videostream";
import Anexos from "./Atendimento.anexos";
import moment from "moment";

export default {
    components: {
        Prontuario,
        VideoStream,
        Anexos,
        Panel,
        ProgressBar,
        Button,
        Dialog,
        ProgressSpinner
    },
    created() {
        Agendamento.find(parseInt(this.$route.params.id)).then(response => {
            if (response.status == 200) {
                this.model = response.data;
                if (this.model.ie_status === "EX") this.guiaExecutada = true;
                this.model.dt_agenda_f = moment(this.model.dt_agenda).format(
                    "DD/MM/YYYY"
                );
                this.model.idade = moment().diff(
                    this.model.dt_nascimento,
                    "years"
                );
                this.statusChamada =
                    this.model.ie_status_chamada != null
                        ? this.options.statusChamada[
                              this.model.ie_status_chamada
                          ]
                        : "Desligado";
            }
        });

        this.checkStatus = setInterval(() => {
            if (this.$refs.videoStream.chamadaAberta) {
                Agendamento.atualizaStatus(parseInt(this.model.id), "MC").then(
                    response => {
                        if (response.status == 200) {
                            this.statusChamada =
                                response.data.ie_status_chamada != null
                                    ? this.options.statusChamada[
                                          response.data.ie_status_chamada
                                      ]
                                    : "Desligado";
                        }
                    }
                );
            }
            Agendamento.find(parseInt(this.model.id)).then(response => {
                if (response.status == 200) {
                    this.statusChamada =
                        response.data.ie_status_chamada != null
                            ? this.options.statusChamada[
                                  response.data.ie_status_chamada
                              ]
                            : "Desligado";
                }
            });
        }, 5000);
    },
    beforeDestroy() {
        clearInterval(this.checkStatus);
        Agendamento.atualizaStatus(parseInt(this.model.id), "DE").then(
            response => {
                if (response.status == 200) {
                    this.statusChamada =
                        response.data.ie_status_chamada != null
                            ? this.options.statusChamada[
                                  response.data.ie_status_chamada
                              ]
                            : "Desligado";
                }
            }
        );
    },
    watch: {
        guiaExecutada: function(val) {
            if (val) {
                let mainWrapper = document.querySelector(
                    ".em-atendimento .main-wrapper"
                );
                mainWrapper.classList.remove("alt");
                mainWrapper.classList.add("alt2");
            }
        }
    },
    data() {
        return {
            checkStatus: null,
            model: {},
            dialogFinalizar: false,
            waitingFinalizar: false,
            guiaExecutada: false,
            atendimentoFinalizado: true,
            statusChamada: "Desligado",
            options: {
                statusChamada: {
                    MC: "Chamando Paciente",
                    EA: "Em Atendimento",
                    PR: "Paciente Desligou",
                    PS: "Paciente na Sala",
                    DE: "Desligado"
                }
            }
        };
    },
    methods: {
        finalizarAtendimento() {
            this.waitingFinalizar = true;
            Agendamento.finalizarGuiaMedico(this.model.id).then(response => {
                this.waitingFinalizar = false;
                if (response.status == 200) {
                    let isoMeet = document.querySelector("#teleconsulta");
                    if (isoMeet) isoMeet.parentNode.removeChild(isoMeet);

                    let mainWrapper = document.querySelector(
                        ".em-atendimento .main-wrapper"
                    );
                    mainWrapper.classList.remove("bubble");
                    mainWrapper.classList.add("alt2");

                    this.atendimentoFinalizado = true;
                    this.dialogFinalizar = false;
                    this.guiaExecutada = true;
                    this.$toast.success("Consulta finalizada", {
                        duration: 3000
                    });
                } else
                    this.$toast.error("Erro ao finalizar", { duration: 3000 });
            });
        }
    }
};
</script>
