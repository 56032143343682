<template lang="pug">
    .atendimento-exames
        Panel.mt-4(v-if="exames.length" header='Exames do paciente' :toggleable='windowInnerWidth < 576' :collapsed='windowInnerWidth < 576')
            .ta-center(v-if='exames.length')
                Card.my-1(v-for='item in exames' :key='item.id' class="ta-left")
                    template(slot="title") {{item.ds_exame}}
                    template(slot="content")
                        span {{item.nm_estabelecimento}}
                        Button(icon='jam jam-search' class="fa-right" @click='openDocument(item.aq_laudo)') Abrir
            p(v-else style='font-size: 12px; font-weight: 700') Sem histórico de outras consultas.
</template>

<style lang="scss">
    .atendimento-fotos {
        .img-anexo {
            max-width: 100%;
            max-height: 640px;
        }
        .img-thumb {
            display: block;
            max-width: 100px;
            max-height: 100px;
        }
    }
</style>

<script>
    import Panel from 'primevue/panel'
    import Galleria from 'primevue/galleria'
    import Button from 'primevue/button'
    import Card from 'primevue/card'

    import { Laudos } from '../../middleware'

    export default {
        components: { Panel, Galleria, Button, Card },
        created () {
            this.idGuiaEletronica = this.$parent.$parent.idGuiaEletronica
            this.id = this.$parent.$parent.model.id
            this.loadExames()
        },
        data () {
            return {
                exames: [],
                waiting: false,
                windowInnerWidth: window.innerWidth,
            }
        },
        methods: {
            openDocument(item){
                console.log(item)
                window.open(item)
            },
            loadExames () {
                console.log(Laudos)
                Laudos.findByHorario(this.id).then(response => {
                    if (response.status == 200) {
                        this.exames = response.data
                        this.waiting = false
                    } else this.waiting = false
                })
            }
        }
    }
</script>